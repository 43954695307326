import React, {ChangeEvent, useEffect, useState} from 'react';
import AwesomeDebouncePromise from "awesome-debounce-promise";
import useConstant from "use-constant";

export type EditableTitleProps = {
    defaultTitle: string
    setTitle: (title: string) => void
}

// Generic reusable hook
const useDebouncedCommit = (commit: any, initialValue: any) => {

    // Handle the input text state
    const [inputText, setInputText] = useState(initialValue);

    // Debounce the original commit function
    const debouncedCommitFunction = useConstant(() =>
        AwesomeDebouncePromise(commit, 300)
    );

    useEffect(() => {
        if (inputText.length != 0) {
            debouncedCommitFunction(inputText)
        }
    }, [debouncedCommitFunction, inputText])

    return {
        inputText,
        setInputText,
    };
};

const EditableTitle = (props: EditableTitleProps) => {

    const {inputText, setInputText} = useDebouncedCommit((input: any) => props.setTitle(input), props.defaultTitle)

    const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setInputText(ev.target.value)
    }

    return (
        <input style={{width: '100%', fontWeight: 'bold', fontSize: '2em', outline: 'none', border: 'none'}}
               width={'100%'} value={inputText} onChange={onChange}/>
    );
};

export default EditableTitle;
