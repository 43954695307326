import React from 'react';
import {
    Row,
    Col
} from "@geist-ui/react";
import "../../../styles/global.css"

type Props = {
    totalDuration: number
    title: string
    setTitle: (title: string) => void
}

const AgendaHeader = ({totalDuration, title, setTitle}: Props) => {


    const changeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(ev.target.value)
    }

    return (
        <Row align={"bottom"}>
            <Col span={15}>
                <input autoFocus style={{
                    border: 'none',
                    width: '100%',
                    fontSize: '2em'
                }}
                       placeholder={'Title'} value={title} onChange={changeHandler}/>
            </Col>
            <Col span={9}>
                Total duration: {totalDuration} min
            </Col>
        </Row>
    );
};

export default AgendaHeader;
