import React from 'react';
import {Button, Tooltip, useClipboard, useToasts} from "@geist-ui/react";
import {Share} from "@geist-ui/react-icons";

export type ShareButtonProps = {
    toastText: string
    meetingId: string
}

const CopyMeetingUrl = ({toastText, meetingId}: ShareButtonProps) => {

    const [, setToast] = useToasts()
    const {copy} = useClipboard()

    const handler = () => {
        copy(`${process.env.REACT_APP_FRONTEND_URL}/meeting/` + meetingId)
        setToast({text: toastText})
    }

    return (
        <Tooltip text={'Copy the meeting link'} enterDelay={1000}>
            <Button size={"small"} auto icon={<Share/>} onClick={handler}/>
        </Tooltip>
    );
};

export default CopyMeetingUrl;
