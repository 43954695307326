import React from "react";

import './styles/footer.css'
import {Button, Grid, Row, Spacer, Text} from "@geist-ui/react";
import {Link, useHistory} from "react-router-dom";
import {LogOut} from "@geist-ui/react-icons";

const Footer = () => {

  return null

  return(
    <div className={'footer'}>
      <Grid.Container gap={0}>
        <Grid xs={24}>
        </Grid>
        <Grid xs={24} sm={12}>
          <Text small b p>takkt minutes</Text>
          {localStorage.getItem('token') &&
          <>
              <Row><Text small><Link to={'/dashboard'}>Dashboard</Link></Text></Row>
          </>
          }
          <Spacer/>
          <Row><Text small><Link to={''} onClick={() => (window.location.href = 'https://www.takkt.io')}>www.takkt.io</Link></Text></Row>
        </Grid>
        <Grid xs={24} sm={12}>
          <Text small b p>&nbsp;</Text>
          <Text small p>Menu</Text>

        </Grid>
        <Grid xs={24}>
          {/*<Text size={10}>&copy; wyte GmbH 2020</Text>*/}
        </Grid>
      </Grid.Container>
    </div>
  )
}


export default Footer;
