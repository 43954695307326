import React, {useMemo, useState} from 'react';
import {Metric, MetricInput, MetricType} from "../../../graphql/generated/graphql";
import {Button, Card, Input, Row, Text} from '@geist-ui/react';
import MetricElement from "./MetricElement";
import * as log from 'loglevel'
import {PlusCircle} from "@geist-ui/react-icons";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';

export type MeetingMetricsProps = {
    metrics: Metric[]
    prevMetrics: Metric[]
    addMetric: (metric: MetricInput) => Promise<unknown>
}

type MetricWithGrowth = Metric & { growth: number | undefined }

const schema = yup.object().shape({
    key: yup.string().required("Metrics must have a name"),
    value: yup.string().required().matches(RegExp('^-?\\d*[\.\,]?\\d+[$%€]?$'), 'Input must be number with optional suffix ($,%,€), e.g 324.53%') // TODO: split regex into smaller parts
})

const MeetingMetrics = ({metrics, prevMetrics, addMetric}: MeetingMetricsProps) => {

    const [expanded, setExpanded] = useState(false);
    const {register, handleSubmit, errors, reset} = useForm<Pick<MetricInput, 'key' | 'value'>>({
        resolver: yupResolver(schema)
    })


    const onSubmit = ({value, key}: any) => {
        const valueMatch = value!.match(RegExp('^-?\\d*[\.\,]?\\d+'))
        const suffixMatch = value!.match(RegExp('[$%€]'))
        addMetric({
            type: MetricType.Number,
            key: key,
            value: String(valueMatch).replace(',', '.'),
            suffix: suffixMatch ? suffixMatch[0] : undefined
        }).then(() => {
            reset()
            setExpanded(false)
        })
    }

    const metricNew = (key: string) => {
        return !metrics.find(m => m.key === key)
    }

    const computedMetrics: MetricWithGrowth[] = useMemo(() => {
        return metrics.map(metric => {
            const prevMetric = prevMetrics ? prevMetrics.find(p => p.key === metric.key) : undefined
            if (prevMetric) {
                let growth = 100 * (Number(metric.value) - Number(prevMetric.value)) / Number(prevMetric.value)
                log.debug(`[METRICS] ${metric.key}  prev: ${prevMetric.value}  now: ${metric.value} => growth: ${growth}%`)
                return {...metric, growth}
            }
            return {...metric, growth: undefined}
        })
    }, [metrics, prevMetrics])

    return (
        <div style={{display: "flex", flexDirection: "row", width: '100%', height: '100%', alignItems: "stretch"}}>
            <div style={{
                display: "flex",
                flexGrow: 1,
                flexFlow: "column no-wrap",
                overflowX: 'auto',
                padding: '5px 0 15px 0'
            }}>
                {computedMetrics.map(m => <MetricElement key={m.key} metric={m} updateMetric={addMetric}/>)}
                {computedMetrics.length === 0 && <div style={{
                    display: "flex",
                    width: '100%',
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: 'center'
                }}>
                    <Card>
                        <Text type={"secondary"}>
                            <b> You can now add metrics to your meetings ✨ </b> <br/>
                            Metrics help you track progress across meetings and deliver incredible results 🚚
                        </Text>
                    </Card>
                </div>}
            </div>
            <div style={{flex: expanded ? '0 0 200px' : '0 0 50px', transition: 'all 0.5s'}}>
                {!expanded
                    ? <div style={{
                        height: '100%',
                        display: "flex",
                        flexFlow: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: '5px 0 15px 0'
                    }} onClick={() => setExpanded(true)}><PlusCircle/></div>
                    :
                    <div style={{'margin': '0 15px 10px 15px'}}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Input autoFocus size={"small"} name={"key"} ref={register({
                                validate: {
                                    unique: (value) => metricNew(value) || 'Metric names must be unique'
                                }
                            })} width={'200px'} label={'Name'}/>
                            {errors.key && <Text small size={10} type={"error"}>{errors.key.message}</Text>}
                            <div style={{marginTop: '8px'}}>
                                <Input size={"small"} name={"value"} ref={register} width={'200px'}
                                       label={'Value'}/>
                                {errors.value && <Text small size={10} type={"error"}>{errors.value.message}</Text>}
                            </div>
                            <Row style={{width: '200px', marginTop: '10px'}} justify={"space-around"}>
                                <Button auto type={"abort"} size={"small"}
                                        onClick={() => setExpanded(false)}>Cancel</Button>
                                <Input size={"small"} type={'submit'}/>
                            </Row>
                        </form>
                    </div>
                }
            </div>
        </div>

    );
};

export default MeetingMetrics;
