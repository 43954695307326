import React, {useEffect, useRef} from 'react';
import {MeetingEvent} from "../../../../graphql/generated/graphql";
import Post from "./Posts/Post";
import _ from "lodash";

type Props = {
    events: MeetingEvent[] | null | undefined
    editing: [(MeetingEvent | null), React.Dispatch<React.SetStateAction<(MeetingEvent | null)>>]
    deletePost: (hash: string) => void
    currentTopic: number
}

const MeetingStage = ({events, editing, deletePost, currentTopic}: Props) => {

    const [, setPost] = editing;
    const ref = useRef<HTMLDivElement>(null);

    const handleResize = () => {
        ref.current && ref.current!.scrollTo({top: ref.current!.scrollHeight, behavior: 'smooth'})
    }

    const setHeight = (current: HTMLDivElement) => {
        if (current.scrollTo) {
            current.scrollTo({
                top: ref.current!.scrollHeight,
                behavior: 'smooth'
            })
        } else {
            current.scrollTop = current.scrollHeight
        }
    }

    const setHeightThrottle = () => {
        // @ts-ignore
        _.throttle(() => ref.current && setHeight(ref.current), 100)
    }

    useEffect(() => {
        window.addEventListener('resize', setHeightThrottle)
        handleResize()
        return () => window.removeEventListener('resize', setHeightThrottle)
    }, [])

    /* Fixes resizing bug on the first render (without resizing) */
    useEffect(() => {
        handleResize()
    }, [events?.length])

    if (!events || events.length < 1) return <p>Add the first note, task or decision</p>
    return (
        <>
            <div ref={ref} style={{width: '100%', height: '100%', overflow: "scroll"}}>
                {events.filter(e => e.agendaItemId === currentTopic).map((post, _index) => (
                    <div key={post.hash} style={{marginTop: '20px'}}>
                        <Post post={post} setEditingPost={setPost} deletePost={deletePost}/>
                    </div>
                ))}
            </div>
        </>
    );
};

export default MeetingStage;
