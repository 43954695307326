import React, {useEffect, useState} from 'react';
import {
    Meeting as MeetingType,
    MeetingEvent,
    MeetingEventInput,
    MetricInput,
    Mutation,
    MutationAddEventToMeetingArgs,
    MutationAddOrUpdateMetricArgs,
    MutationAddParticipantToMeetingArgs,
    MutationDeletePostArgs,
    MutationSetTypingStatusArgs,
    MutationUpdateCurrentTopicArgs,
    MutationUpdateOnlineStatusArgs,
    MutationUpdatePostArgs,
    Participant,
    TopicUpdateDirection
} from "../../graphql/generated/graphql";
import MeetingRender from "./components/MeetingRender";
import {useMutation, useQuery} from "@apollo/client";
import {useParams} from "react-router";
import update from 'immutability-helper'
import crypto from "crypto";
import {
    ADD_OR_UPDATE_METRIC,
    HEARTBEAT_MUTATION,
    MEETING_CHANGE_CURRENT_TOPIC,
    MEETING_QUERY,
    MEETING_UPDATED_SUBSCRIPTION,
    PARTICIPANT_ADD_MUTATION,
    POST_ADD_MUTATION,
    POST_DELETE,
    POST_DELETED_SUBSCRIPTION,
    POST_NEW_SUBSCRIPTION,
    POST_UPDATE_MUTATION,
    POSTS_UPDATE_SUBSCRIPTION,
    SET_TYPING_STATUS
} from "../../statements.graphql";
import {useInput, useModal, useToasts} from "@geist-ui/react";
import log from 'loglevel';

require('dotenv').config()

// TODO cookie consent


const createEventHash = (event: any) => {
    return crypto.createHash('md5').update((event.toString() + Date.now())).digest('hex').substr(0, 14)
}

const MeetingWithData = ({currentTopic, topicHistory}: { currentTopic: number, topicHistory: any }) => {

    /* Other Hooks */
    const {meetingId} = useParams();
    const [, setToast] = useToasts()
    // noinspection JSUnusedLocalSymbols
    const {visible: modalVisible, setVisible: setModalVisible, bindings: modalBindings} = useModal()


    /* Local states */
    const [participant, setParticipant] = useState<Participant>()
    const [editingPost, setEditingPost] = useState<MeetingEvent | null>(null)
    // noinspection JSUnusedLocalSymbols
    const {state: nicknameInput, setState: setNicknameInput, reset, bindings: nicknameBindings} = useInput('')


    /* Server query and mutations */
    const {loading, error, data, subscribeToMore} = useQuery<{ meeting: MeetingType }, { publicId: string }>(MEETING_QUERY, {
        variables: {
            publicId: meetingId
        }
    });

    const [addParticipantToMeeting] = useMutation<{ addParticipantToMeeting: Mutation['addParticipantToMeeting'] }, MutationAddParticipantToMeetingArgs>(PARTICIPANT_ADD_MUTATION)
    const [addEventToMeeting] = useMutation<Mutation['addEventToMeeting'], MutationAddEventToMeetingArgs>(POST_ADD_MUTATION)
    const [updatePostMutation] = useMutation<Mutation['updatePost'], MutationUpdatePostArgs>(POST_UPDATE_MUTATION)
    const [deletePostMutation] = useMutation<Mutation['deletePost'], MutationDeletePostArgs>(POST_DELETE)
    const [updateCurrentTopic] = useMutation<Mutation['updateCurrentTopic'], MutationUpdateCurrentTopicArgs>(MEETING_CHANGE_CURRENT_TOPIC)
    const [updateOnlineStatus] = useMutation<Mutation['updateOnlineStatus'], MutationUpdateOnlineStatusArgs>(HEARTBEAT_MUTATION)
    const [setTypingStatusMutation] = useMutation<Mutation['setTypingStatus'], MutationSetTypingStatusArgs>(SET_TYPING_STATUS)
    const [addOrUpdateMetricMutation] = useMutation<Mutation['addOrUpdateMetric'], MutationAddOrUpdateMetricArgs>(ADD_OR_UPDATE_METRIC)

    /* Post mutation functions */
    const addPost = (event: Pick<MeetingEventInput, 'type' | 'textBody'>) => {
        const fEvent = {
            ...event,
            authorAvatar: {avatarType: participant?.avatar.avatarType, avatarObject: participant?.avatar.avatarObject},
            agendaItemId: currentTopic
        }
        addEventToMeeting({
            variables: {
                meetingEvent: {...fEvent, hash: createEventHash(fEvent),},
                publicId: meetingId
            }
        }).then(() => {
        })
    }
    const updatePost = (event: MeetingEventInput) => {
        try {
            delete (event as any).__typename
            delete (event as any).author
        } catch (error) {
            log.debug(error)
        }

        updatePostMutation({
            variables: {
                publicId: meetingId,
                post: {
                    ...event,
                    authorAvatar: {
                        avatarObject: event.authorAvatar.avatarObject,
                        avatarType: event.authorAvatar.avatarType
                    },

                }
            }
        }).then(() => setEditingPost(null))
    }
    const deletePost = (hash: string) => deletePostMutation({variables: {publicId: meetingId, hash}})
    const topicForward = () => {
        updateCurrentTopic({
            variables: {
                publicId: meetingId, direction: TopicUpdateDirection.Next
            }
        }).then(() => setToast({
            text: 'You moved the meeting to the next topic',
            type: "success",
            actions: [{name: 'Undo', handler: () => topicBackward(), passive: true}]
        }))
    }
    const topicBackward = () => {
        updateCurrentTopic({
            variables: {
                publicId: meetingId,
                direction: TopicUpdateDirection.Prev
            }
        }).then(() => setToast({
            text: `There you go.`,
            type: "success"
        }))
    }
    const setTypingStatus = (typingStatus: boolean) => {
        setTypingStatusMutation({
            variables: {
                publicId: meetingId,
                typingStatus
            }
        }).then(() => {
        })
    }

    const addMetric = (metric: MetricInput) => {
        return new Promise(((resolve, reject) => {
            addOrUpdateMetricMutation({
                variables: {
                    publicId: meetingId,
                    metric
                }
            })
                .then(() => resolve())
                .catch(() => reject())
        }))
    }

    // Heartbeat for online status
    useEffect(() => {
        const interval = setInterval(() => {
            updateOnlineStatus({
                variables: {
                    publicId: meetingId
                }
            }).then(() => {
                log.debug('heartbeat')
            });
        }, 5000)
        return () => clearInterval(interval)
    })



    /* Join meeting; Save information in cookie */
    useEffect(() => {
        if (localStorage.getItem('token')) {
            addParticipantToMeeting({variables: {publicId: meetingId}})
                .then((res) => {
                    log.debug('add participant', res.data)
                    setParticipant(res.data!.addParticipantToMeeting!)
                })
        } else {
            window.location.assign('https://www.takkt.io')
        }
    }, [])

    /* Subscribe to updates (posts/participants) */
    useEffect(() => {
        if (subscribeToMore) {
            const unsubPosts = subscribeToMore({
                document: POST_NEW_SUBSCRIPTION,
                variables: {publicId: meetingId},
                updateQuery: (prev, {subscriptionData}) => {
                    log.debug('new post received', subscriptionData)
                    return update(prev, {meeting: {meetingEvents: {$push: [(subscriptionData as any).data.postAdded]}}})
                }
            })

            const unsubParticipants = subscribeToMore({
                document: MEETING_UPDATED_SUBSCRIPTION,
                variables: {publicId: meetingId},
                updateQuery: (prev, {subscriptionData}) => {
                    //log.debug('participant joined', subscriptionData)
                    return update(prev, {meeting: {participants: {$set: [...(subscriptionData as any).data.meetingUpdated.participants]}}})
                }
            })

            const unsubPostUpdate = subscribeToMore({
                document: POSTS_UPDATE_SUBSCRIPTION,
                variables: {publicId: meetingId},
                updateQuery: (prev, {subscriptionData}) => {
                    log.debug('post got updated', subscriptionData)
                    const index = (prev.meeting.meetingEvents as MeetingEvent[])
                        .findIndex((e) => e.hash === (subscriptionData as any).data.postUpdate.hash)
                    return index !== -1
                        ? update(prev, {meeting: {meetingEvents: {[index]: {textBody: {$set: (subscriptionData as any).data.postUpdate.textBody}}}}})
                        : prev
                }
            })

            const unsubPostDeleted = subscribeToMore({
                document: POST_DELETED_SUBSCRIPTION,
                variables: {publicId: meetingId},
                updateQuery: (prev, {subscriptionData}) => {
                    log.debug('post got deleted', subscriptionData)
                    const index = (prev.meeting.meetingEvents as MeetingEvent[])
                        .findIndex((e) => e.hash === ((subscriptionData as any).data.postDeleted! as string))
                    return index !== -1
                        ? update(prev, {meeting: {meetingEvents: {$splice: [[index, 1]]}}})
                        : prev
                }
            })

            return () => {
                unsubPosts()
                unsubParticipants()
                unsubPostUpdate()
                unsubPostDeleted()
            }
        }
    }, [loading, data, meetingId, subscribeToMore, participant])


    return (
        <MeetingRender loading={loading}
                       error={error}
                       meeting={data?.meeting}
                       addEvent={addPost}
                       topics={{topicIndex: currentTopic, forward: topicForward, backward: topicBackward}}
                       editing={[editingPost, setEditingPost]}
                       updatePost={updatePost}
                       deletePost={deletePost}
                       topicHistory={topicHistory}
                       setTypingStatus={setTypingStatus}
                       addMetric={addMetric}
        />
    );
};

export default MeetingWithData;
