import React from 'react';
import {BrowserRouter as Router, Redirect, Switch} from "react-router-dom";
import Create from "./1-Create";
import Meeting from "./2-Meeting";
import Summary from "./3-Summary";
import DashboardWithData from "./4-Dashboard/DashboardWithData";
import {Helmet} from "react-helmet";
import TokenHandler from "./Helper/TokenHandler";
import BadLogin from "./Helper/BadLogin";
import Route from './ProtectedRoute'
import Followup from "./3-1-Follow_up/Followup";
import SeriesWithData from "./Series/SeriesWithData";

const Routes = () => {

    return (
        <Router>
            <Switch>
                <Route path={'/create'}>
                    <Helmet>
                        <title>Create - Takkt</title>
                    </Helmet>
                    <Create/>
                </Route>

                <Route path={'/meeting/:meetingId'}>
                    <Helmet>
                        <title>Meeting - Takkt</title>
                    </Helmet>
                    <Meeting/>
                </Route>

                <Route path={'/series/:seriesId'}>
                    <Helmet>
                        <title>Series - Takkt</title>
                    </Helmet>
                    <SeriesWithData/>
                </Route>

                <Route path={'/summary/:meetingId'}>
                    <Summary/>
                </Route>

                <Route path={'/followup/:publicId'}>
                    <Followup/>
                </Route>

                <Route noToken path={'/blackmagic/:token/:base64redirect'}>
                    <TokenHandler/>
                </Route>

                <Route noToken path={'/blackmagic/:token'}>
                    <TokenHandler/>
                </Route>
                <Route path={'/error'}>
                    <BadLogin/>
                </Route>

                <Route path={'/dashboard'}>
                    <Helmet>
                        <title>Dashboard - Takkt</title>
                    </Helmet>
                    <DashboardWithData/>
                </Route>

                <Route default>
                    <Redirect to={'/create'}/>
                </Route>

            </Switch>
        </Router>
    );
};

export default Routes;
