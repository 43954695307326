import React, {useEffect} from "react";
import {gql, useQuery} from "@apollo/client";
import {Redirect, useParams} from "react-router";
import {Card, Loading, Text} from "@geist-ui/react";
import WaitingPage from "./components/WaitingPage";
import MeetingWithData from "./MeetingWithData";
import update from 'immutability-helper'


const MEETING_STATUS = gql`
    query Meeting($meetingId: ID!) {
        meeting(publicId: $meetingId) {
            title
            publicId
            start
            started
            closed
            currentTopic
            agenda {topic, durationInMinutes}
            topicHistory {topic, duration, switchTimeStamp}
        }
    }
`

const MEETING_STATUS_SUB = gql`
    subscription MeetingSub($meetingId: ID!) {
        meetingUpdated(publicId: $meetingId) {
            publicId
            started
            closed
            currentTopic
            agenda {
                topic
                durationInMinutes
            }
            topicHistory {topic, duration, switchTimeStamp}
        }
    }
`

const Meeting = () => {

    const {meetingId} = useParams()

    const {loading, error, data, subscribeToMore} = useQuery(MEETING_STATUS, {variables: {meetingId}})

    useEffect(() => {
        subscribeToMore && subscribeToMore({
            document: MEETING_STATUS_SUB,
            variables: {meetingId},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev
                if (subscriptionData.data.meetingUpdated.agenda) {
                    prev = update(prev, {meeting: {agenda: {$set: subscriptionData.data.meetingUpdated.agenda}}})
                }
                if (subscriptionData.data.meetingUpdated.closed) {
                    return update(prev, {meeting: {closed: {$set: subscriptionData.data.meetingUpdated.closed}}})
                }
                return update(prev, {meeting: {started: {$set: subscriptionData.data.meetingUpdated.started}}})
            }
        })
    }, [subscribeToMore, meetingId])

    if (loading) return <Loading/>
    if (error) return <Card><Text type={'error'}>{error.message}</Text></Card>

    // TODO 404, meeting not found error

    if (data && !data.meeting.started) return <WaitingPage meeting={data.meeting}/>

    if (data.meeting.closed) return <Redirect to={`/summary/${meetingId}`}/>

    return (
        <MeetingWithData currentTopic={data.meeting.currentTopic} topicHistory={data.meeting.topicHistory}/>
    )
}

export default Meeting
