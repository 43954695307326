import {Button, Card, Grid, Page, Popover, Row, Spacer, Text, useModal} from "@geist-ui/react";
import {useHistory, useParams} from "react-router";
import {useMutation, useQuery} from "@apollo/client";
import React, {useEffect, useState} from "react";
import Header from "../../Header";
import {Archive, Loader, Plus} from "@geist-ui/react-icons";
import Flexbox from "../../Flexbox";
import Tasks from "./Tasks/Tasks";
import {ADD_OR_UPDATE_METRIC, MEETING_CREATE, SUMMARY_QUERY, TASK_SUBSCRIPTION} from "../../statements.graphql";
import update from 'immutability-helper'
import {Helmet} from "react-helmet";
import CreateFollowupModal from "../3-1-Follow_up/CreateFollowupModal";
import {Meeting, MetricInput, Mutation, MutationAddOrUpdateMetricArgs} from "../../graphql/generated/graphql";
import Participants from "../../components/Participants";
import log from "loglevel";
import MeetingMetrics from "../2-Meeting/components/MeetingMetrics";

const Summary = () => {

    const history = useHistory();
    const {meetingId} = useParams();

    const [meeting, setMeeting] = useState<Partial<Meeting>>({});
    const [summary, setSummary] = useState<any>({});

    const createFollowUpModal = useModal(false);

    const {data, loading, subscribeToMore, refetch} = useQuery<any>(SUMMARY_QUERY, {
        variables: {
            publicId: meetingId
        }
    });

    const [createMeetingMutation] = useMutation<{ createMeeting: Mutation['createMeeting'] }, any>(MEETING_CREATE)
    const [addOrUpdateMetricMutation] = useMutation<Mutation['addOrUpdateMetric'], MutationAddOrUpdateMetricArgs>(ADD_OR_UPDATE_METRIC)

    const createFollowup = (title: string, start: Date, invitees: string[]): Promise<any> => {
        const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);

        return new Promise<string>(((resolve, reject) => {
            createMeetingMutation({
                variables: {
                    meetingInput: {
                        title,
                        start,
                        meetingConfig: data.meeting.meetingConfig,
                        agenda: JSON.parse(JSON.stringify(data.meeting.agenda), omitTypename),
                        previousPublicId: data.meeting.publicId,
                        invitees
                    }
                }
            })
                .then((res) => {
                    resolve(res.data!.createMeeting!.publicId
                    )
                })
                .catch((err) => reject(err))
        }))

    }

    const addMetric = (metric: MetricInput) => {
        return new Promise(((resolve, reject) => {
            addOrUpdateMetricMutation({
                variables: {
                    publicId: meetingId,
                    metric
                }
            })
                .then(() => {
                        refetch().then(() => {
                        })
                    resolve()
                    }
                )
                .catch(() => reject())
        }))
    }

    useEffect(() => {
        if (data && data.meeting) {
            setMeeting(data.meeting)
            setSummary(data.meeting.summary)
        }
    }, [data, loading])


    const NoteSection = (props: any) => {
        return (
            <Card key={props.counter}
                  style={{marginBottom: '10px'}}>
                <Text b>
                    {props.counter}. {meeting.agenda![props.counter - 1].topic}
                </Text>
                {
                    summary && summary.notes && summary.notes.objects.filter((item: any) => {
                        return (item.agendaItemId === props.counter - 1);
                    }).map((item: any) => {
                            return (
                                <Text style={{whiteSpace: "pre-wrap"}}>{item.textBody}</Text>
                            )
                        }
                    )
              }


          </Card>)
    }

    useEffect(() => {
        if (data && subscribeToMore) {
            const unsubTaskDoneStatus = subscribeToMore({
                document: TASK_SUBSCRIPTION,
                variables: {publicId: meetingId},
                updateQuery: (prev, {subscriptionData}) => {
                    log.debug("new task update", subscriptionData.data.taskStateUpdate)
                    const index = prev.meeting.summary.tasks.objects
                        .findIndex((o: any) => o.hash === subscriptionData.data.taskStateUpdate.hash)
                    log.trace("index", index)
                    return index !== -1
                        ? update(prev, {
                            meeting: {
                                summary: {
                                    tasks: {
                                        objects: {
                                            [index]: {done: {$set: subscriptionData.data.taskStateUpdate.done}}
                                        }
                                    }
                                }
                            }
                        })
                        : prev
                }
            })
            return () => {
                unsubTaskDoneStatus()
            }
        }
    }, [loading])

    return (
        <Page size={'large'}>
            <Helmet>
                <title>Summary - Takkt</title>
            </Helmet>

            <Header headerTitle={'Summary'}/>
            <Page.Content>

                {meeting &&
                <CreateFollowupModal modal={createFollowUpModal} meeting={meeting} createFollowup={createFollowup}/>}

                <Row justify={"space-between"}>
                    <div>
                        <Text h2>{meeting.title}</Text>
                        <Participants participants={meeting.participants} showName={true}/>
                    </div>

                    <Flexbox flexDirection={"row"} alignItems={"center"}>
                        <Popover content={<Card type={"lite"}>
                            <Button icon={<Archive/>} onClick={() => createFollowUpModal.setVisible(true)}>this
                                agenda</Button>
                            <Spacer y={0.4}/>
                            <Button icon={<Plus/>} type={"success"} onClick={() => history.push({
                                pathname: '/create',
                                state: {prev: meetingId, prevTitle: meeting.title}
                            })}>new agenda</Button>
                        </Card>}>
                            <Button auto icon={<Loader/>}>create Follow-up</Button>
                        </Popover>
                    </Flexbox>
                </Row>
                {
                    data &&
                    <div style={{height: '140px', margin: '20px 0'}}>
                        <MeetingMetrics metrics={data.meeting.metrics!} prevMetrics={data.meeting.previous?.metrics!}
                                        addMetric={addMetric}/>
                    </div>
                }

                <Row>
                    <Grid.Container gap={2} justify="center">
                        <Grid xs={24} sm={16}>
                            <Card style={{background: '#f6f6f6'}}>
                                <Text h4>Notes</Text>
                                {meeting && meeting.agenda &&
                                meeting.agenda.map((item: any, index: number) => {
                                    return <NoteSection key={index} counter={index + 1}/>
                                })
                                }


                            </Card>
                        </Grid>
                        <Grid xs={24} sm={8}>
                            <Card style={{background: '#f6f6f6'}}>
                                <Text h4>Tasks</Text>
                                {
                                    summary && summary.tasks && summary.tasks.objects &&
                                    <Tasks tasks={summary.tasks.objects}/>
                                }
                            </Card>
                            <br/>
                            <Card>
                                <Text h4>Decisions</Text>
                                {
                                    summary && summary.decisions && summary.decisions.objects &&
                                    <div>
                                        {summary.decisions.objects.map((item: any) => {
                                            return <Text key={item.hash}
                                                         style={{
                                                             marginBottom: '10px',
                                                             whiteSpace: "pre-wrap"
                                                         }}>{item.textBody}</Text>
                                        })}
                                    </div>
                                }
                            </Card>
                        </Grid>
                    </Grid.Container>
                </Row>
            </Page.Content>
        </Page>

    )
}

export default Summary
