import React, {useState} from 'react';
import {MeetingEvent, Mutation, MutationSetTaskDoneArgs} from "../../../graphql/generated/graphql";
import {Checkbox, Spinner, Text} from "@geist-ui/react";
import {CheckboxEvent} from "@geist-ui/react/dist/checkbox/checkbox";
import {useMutation, useSubscription} from "@apollo/client";
import {TASK_SET_DONE, TASK_SUBSCRIPTION} from "../../../statements.graphql";
import {Duration} from "luxon";

const TaskElement = ({event, meetingId}: { event: MeetingEvent, meetingId: string }) => {

    const [setTaskDone, {loading}] = useMutation<{ setTaskDone: Mutation["setTaskDone"] }, MutationSetTaskDoneArgs>(TASK_SET_DONE)

    const {data} = useSubscription(TASK_SUBSCRIPTION, {
        variables: {publicId: meetingId}
    })

    const onChange = (e: CheckboxEvent) => {
        setTaskDone({
            variables: {
                publicId: meetingId,
                hash: event.hash,
                done: e.target.checked
            }
        }).then(() => {
        })
    }

    return (
        <div style={{position: "relative"}}>
            {loading &&
            <Spinner color={'grey'} size={"mini"} style={{position: "absolute", left: '-17px', top: '0.4em'}}/>}
            <Checkbox checked={event.done || false} onChange={onChange}/>
            {event.textBody} {/*TODO style strikethrough*/}
            {event.dueDate && <Text type={"warning"} small>Due
                in {Math.floor(Duration.fromMillis(event.dueDate - Date.now()).as('days'))} days</Text>}
        </div>
    );
};

export default TaskElement;
