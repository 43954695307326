import React, {useMemo} from 'react';
import {Badge, Button, Card, Grid, Row, Text} from "@geist-ui/react";
import {Meeting} from "../../graphql/generated/graphql";
import {useHistory} from "react-router";
import Participants from "../../components/Participants";
import MeetingItemMenu from "../../components/MeetingItemMenu";

type MeetingItemProps = {
    meeting: Partial<Meeting>
    partOfSeries?: boolean
    refetch?: () => void
}

const MeetingItem = ({meeting, partOfSeries, refetch}: MeetingItemProps) => {

    const history = useHistory();
    const go2Meeting = () => history.push(`/meeting/${meeting.publicId}`)

    const meetingStatus = useMemo(() => {
        if (meeting.closed) {
            return {label: 'Summary', type: 'default'}
        }
        if (meeting.started) {
            return {label: 'Join', type: 'success'}
        } else {
            return {label: 'Waiting', type: 'secondary'}
        }
    }, [meeting])

    return (
        <Card style={{marginTop: '10px'}}>
            <Grid.Container>
                <Grid xs={18} md={10} style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                    <Row>
                        <Text small b>{meeting.title}</Text>
                    </Row>
                    <Row>
                        <Text small>
                            {new Date(meeting.start).toLocaleString(undefined).slice(0, -3)}
                        </Text>
                    </Row>
                </Grid>

                <Grid xs={6} md={10}>
                    <Grid.Container>
                        <Grid xs={0} md={16}>
                            <Participants participants={meeting.participants}/>
                        </Grid>
                        <Grid xs={24} md={8}>
                            <Row justify={"end"}>
                                {
                                    meeting.series && (
                                        <Badge size={"mini"} type="warning">Series</Badge>
                                    )
                                }
                                {
                                    !meeting.series && meeting.previous && !partOfSeries && (
                                        <Badge size={"mini"} type={"success"}>Follow-up</Badge>
                                    )
                                }
                            </Row>

                        </Grid>
                    </Grid.Container>
                </Grid>

                <Grid xs={24} md={0}>
                    <div style={{height: '20px'}}/>
                </Grid>

                <Grid xs={24} md={4}>
                    <Row>
                        <Button onClick={go2Meeting} type={meetingStatus.type as "default"}
                                size={"small"}>{meetingStatus.label}</Button>
                    </Row>
                </Grid>

                <Grid xs={0} md={0}>
                    <MeetingItemMenu meetingId={meeting.publicId!} refetch={refetch!}/>
                </Grid>
            </Grid.Container>
        </Card>
    );
};

export default MeetingItem;
