import React from 'react';
import {Participant} from "../../../../graphql/generated/graphql";
import {Tooltip} from '@geist-ui/react';

type OnlineAvatarsProps = {
    participants: Participant[]
}

const OnlineAvatars = ({participants}: OnlineAvatarsProps) => {
    return (
        <div>
            {participants.filter((p) => p.online + 25 * 1000 > Date.now())
              .map((p) => {
                return (
                    <div key={p.user.userId}
                         style={{display: 'inline', marginLeft: '7px'}}>
                        <Tooltip text={p.user.nickname}>{p.avatar!.avatarObject}</Tooltip>
                    </div>
                )
            })}
        </div>
    );
};

export default OnlineAvatars;
