export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type Subscription = {
  __typename?: 'Subscription';
  /** Meeting subscriptions */
  postAdded?: Maybe<MeetingEvent>;
  meetingUpdated?: Maybe<Meeting>;
  taskStateUpdate?: Maybe<MeetingEvent>;
  postUpdate?: Maybe<MeetingEvent>;
  postDeleted?: Maybe<Scalars['String']>;
  userTyping?: Maybe<Array<Maybe<User>>>;
};


export type SubscriptionPostAddedArgs = {
  publicId?: Maybe<Scalars['ID']>;
};


export type SubscriptionMeetingUpdatedArgs = {
  publicId?: Maybe<Scalars['ID']>;
};


export type SubscriptionTaskStateUpdateArgs = {
  publicId?: Maybe<Scalars['ID']>;
};


export type SubscriptionPostUpdateArgs = {
  publicId?: Maybe<Scalars['ID']>;
};


export type SubscriptionPostDeletedArgs = {
  publicId?: Maybe<Scalars['ID']>;
};


export type SubscriptionUserTypingArgs = {
  publicId?: Maybe<Scalars['ID']>;
};

export type Query = {
  __typename?: 'Query';
  ping?: Maybe<Scalars['String']>;
  /** User queries */
  user?: Maybe<User>;
  /** Meeting queries */
  meeting?: Maybe<Meeting>;
  getAgenda: Array<AgendaItem>;
  meetingConfigOptions: Array<MeetingConfigOption>;
  /** Series queries */
  series?: Maybe<Series>;
};


export type QueryMeetingArgs = {
  publicId?: Maybe<Scalars['ID']>;
};


export type QueryGetAgendaArgs = {
  businessSubject: Scalars['String'];
  numberOfParticipants: Scalars['String'];
  durationInMinutes: Scalars['String'];
};


export type QuerySeriesArgs = {
  publicId?: Maybe<Scalars['ID']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * User mutations
   * updateUser(userUpdate: UserUpdate): User
   * deleteUser(userId: String): String
   */
  addMeetingToUser?: Maybe<User>;
  archiveMeeting?: Maybe<User>;
  addSeriesToUser?: Maybe<User>;
  removeSeriesFromUser?: Maybe<User>;
  /** Meeting mutations */
  createMeeting?: Maybe<Meeting>;
  addParticipantToMeeting?: Maybe<Participant>;
  addEventToMeeting?: Maybe<Scalars['String']>;
  deleteEventFromMeeting?: Maybe<Scalars['String']>;
  closeMeeting?: Maybe<Scalars['String']>;
  startMeeting?: Maybe<Scalars['String']>;
  setTaskDone?: Maybe<Scalars['String']>;
  updateCurrentTopic?: Maybe<Scalars['String']>;
  updatePost?: Maybe<Scalars['String']>;
  deletePost?: Maybe<Scalars['String']>;
  updateOnlineStatus?: Maybe<Scalars['String']>;
  setTypingStatus?: Maybe<Scalars['String']>;
  updateMeetingSettings?: Maybe<Scalars['String']>;
  /** Series mutations */
  createSeries?: Maybe<Series>;
  updateSeries?: Maybe<Series>;
  createNext?: Maybe<Meeting>;
  addOrUpdateMetric?: Maybe<Scalars['String']>;
};


export type MutationAddMeetingToUserArgs = {
  meetingId?: Maybe<Scalars['ID']>;
};


export type MutationArchiveMeetingArgs = {
  meetingId?: Maybe<Scalars['ID']>;
};


export type MutationAddSeriesToUserArgs = {
  seriesId?: Maybe<Scalars['ID']>;
};


export type MutationRemoveSeriesFromUserArgs = {
  seriesId?: Maybe<Scalars['ID']>;
};


export type MutationCreateMeetingArgs = {
  meetingInput?: Maybe<MeetingInput>;
};


export type MutationAddParticipantToMeetingArgs = {
  publicId?: Maybe<Scalars['ID']>;
};


export type MutationAddEventToMeetingArgs = {
  publicId?: Maybe<Scalars['ID']>;
  meetingEvent?: Maybe<MeetingEventInput>;
};


export type MutationDeleteEventFromMeetingArgs = {
  meetingId?: Maybe<Scalars['ID']>;
  meetingEventId?: Maybe<Scalars['String']>;
};


export type MutationCloseMeetingArgs = {
  publicId: Scalars['ID'];
};


export type MutationStartMeetingArgs = {
  publicId?: Maybe<Scalars['ID']>;
};


export type MutationSetTaskDoneArgs = {
  publicId?: Maybe<Scalars['ID']>;
  hash?: Maybe<Scalars['String']>;
  done?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateCurrentTopicArgs = {
  publicId?: Maybe<Scalars['ID']>;
  direction?: Maybe<TopicUpdateDirection>;
};


export type MutationUpdatePostArgs = {
  publicId?: Maybe<Scalars['ID']>;
  post?: Maybe<MeetingEventInput>;
};


export type MutationDeletePostArgs = {
  publicId?: Maybe<Scalars['ID']>;
  hash?: Maybe<Scalars['String']>;
};


export type MutationUpdateOnlineStatusArgs = {
  publicId?: Maybe<Scalars['ID']>;
};


export type MutationSetTypingStatusArgs = {
  publicId?: Maybe<Scalars['ID']>;
  typingStatus?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateMeetingSettingsArgs = {
  publicId?: Maybe<Scalars['ID']>;
  meetingSettingsUpdate?: Maybe<MeetingSettingsUpdateInput>;
};


export type MutationCreateSeriesArgs = {
  seriesInput?: Maybe<SeriesInput>;
};


export type MutationUpdateSeriesArgs = {
  publicId: Scalars['ID'];
  update?: Maybe<SeriesUpdate>;
};


export type MutationCreateNextArgs = {
  publicId: Scalars['ID'];
};


export type MutationAddOrUpdateMetricArgs = {
  publicId: Scalars['ID'];
  metric?: Maybe<MetricInput>;
};

export enum BusinessSubject {
  StatusUpdate = 'STATUS_UPDATE',
  DecisionMaking = 'DECISION_MAKING',
  ProblemSolving = 'PROBLEM_SOLVING',
  InformationSharing = 'INFORMATION_SHARING',
  Innovation = 'INNOVATION',
  Planning = 'PLANNING'
}

export enum TopicUpdateDirection {
  Prev = 'PREV',
  Next = 'NEXT'
}

export type User = {
  __typename?: 'User';
  _id?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
  email: Scalars['String'];
  emailConfirmed?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickname: Scalars['String'];
  meetings: Array<Meeting>;
  archivedMeetings?: Maybe<Array<Meeting>>;
  series: Array<Series>;
  dateCreated?: Maybe<Scalars['Date']>;
};

export type UserUpdate = {
  userId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type Meeting = {
  __typename?: 'Meeting';
  publicId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  host?: Maybe<User>;
  invitees?: Maybe<Array<Maybe<User>>>;
  agenda: Array<AgendaItem>;
  meetingConfig: MeetingConfig;
  participants: Array<Participant>;
  meetingEvents: Array<Maybe<MeetingEvent>>;
  summary?: Maybe<MeetingSummary>;
  availableAvatars?: Maybe<Array<Maybe<Avatar>>>;
  currentTopic?: Maybe<Scalars['Int']>;
  topicHistory: Array<TopicHistoryItem>;
  created?: Maybe<Scalars['Date']>;
  started?: Maybe<Scalars['Date']>;
  closed?: Maybe<Scalars['Date']>;
  previous?: Maybe<Meeting>;
  next?: Maybe<Meeting>;
  series?: Maybe<Series>;
  start?: Maybe<Scalars['Date']>;
  metrics: Array<Metric>;
};

export type MeetingInput = {
  title?: Maybe<Scalars['String']>;
  agenda?: Maybe<Array<Maybe<AgendaItemInput>>>;
  meetingConfig?: Maybe<MeetingConfigInput>;
  invitees?: Maybe<Array<Scalars['String']>>;
  previousPublicId?: Maybe<Scalars['String']>;
  seriesPublicId?: Maybe<Scalars['String']>;
  seriesObjectRef?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Date']>;
  metrics?: Maybe<Array<MetricInput>>;
};

export type MeetingSummary = {
  __typename?: 'MeetingSummary';
  notes?: Maybe<MeetingSummaryItem>;
  tasks?: Maybe<MeetingSummaryItem>;
  decisions?: Maybe<MeetingSummaryItem>;
};

export type MeetingSummaryItem = {
  __typename?: 'MeetingSummaryItem';
  identifier?: Maybe<MeetingEventType>;
  objects?: Maybe<Array<Maybe<MeetingEvent>>>;
};

export type MeetingConfig = {
  __typename?: 'MeetingConfig';
  businessSubject: BusinessSubject;
  numberOfParticipants: Scalars['String'];
  durationInMinutes: Scalars['String'];
};

export type MeetingConfigInput = {
  businessSubject: BusinessSubject;
  numberOfParticipants: Scalars['String'];
  durationInMinutes: Scalars['String'];
};

export type MeetingConfigOption = {
  __typename?: 'MeetingConfigOption';
  identifier: Scalars['String'];
  label: Scalars['String'];
  options: Array<MeetingConfigOptionItem>;
};

export type MeetingConfigOptionItem = {
  __typename?: 'MeetingConfigOptionItem';
  label: Scalars['String'];
  graphic?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  val: Scalars['String'];
};

export type MeetingSettingsUpdateInput = {
  title?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Date']>;
  agenda?: Maybe<Array<Maybe<AgendaItemInput>>>;
  hostEmail?: Maybe<Scalars['String']>;
  addInviteesEmails?: Maybe<Array<Scalars['String']>>;
  removeInviteesEmails?: Maybe<Array<Scalars['String']>>;
  previousMeetingId?: Maybe<Scalars['ID']>;
  nextMeetingId?: Maybe<Scalars['ID']>;
  updateSeries?: Maybe<Scalars['Boolean']>;
};

export type MeetingEvent = {
  __typename?: 'MeetingEvent';
  type?: Maybe<MeetingEventType>;
  hash?: Maybe<Scalars['String']>;
  author: User;
  authorAvatar: Avatar;
  agendaItemId: Scalars['Int'];
  textBody?: Maybe<Scalars['String']>;
  responsible?: Maybe<User>;
  dueDate?: Maybe<Scalars['Date']>;
  done?: Maybe<Scalars['Boolean']>;
  timestamp_created?: Maybe<Scalars['Date']>;
};

export type MeetingEventInput = {
  type: MeetingEventType;
  hash: Scalars['String'];
  authorAvatar: AvatarInput;
  agendaItemId: Scalars['Int'];
  responsible?: Maybe<Scalars['String']>;
  textBody?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  done?: Maybe<Scalars['Boolean']>;
};

export enum MeetingEventType {
  Note = 'NOTE',
  Task = 'TASK',
  File = 'FILE',
  Decision = 'DECISION'
}

export type TopicHistoryItem = {
  __typename?: 'TopicHistoryItem';
  topic?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Float']>;
  switchTimeStamp?: Maybe<Scalars['Float']>;
};

export type AgendaItem = {
  __typename?: 'AgendaItem';
  topic: Scalars['String'];
  durationInMinutes: Scalars['Int'];
};

export type AgendaItemInput = {
  topic?: Maybe<Scalars['String']>;
  durationInMinutes?: Maybe<Scalars['Int']>;
};

export type Series = {
  __typename?: 'Series';
  publicId: Scalars['String'];
  title: Scalars['String'];
  meetings: Array<Meeting>;
  host: User;
  participants: Array<User>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  interval?: Maybe<SeriesInterval>;
  nextMeetingStart?: Maybe<Scalars['Date']>;
};

export type SeriesInput = {
  title: Scalars['String'];
  participantEmails: Array<Scalars['String']>;
  start: Scalars['Date'];
  end?: Maybe<Scalars['Date']>;
  interval?: Maybe<SeriesInterval>;
  agenda?: Maybe<Array<Maybe<AgendaItemInput>>>;
  meetingConfig?: Maybe<MeetingConfigInput>;
  previousPublicId?: Maybe<Scalars['String']>;
};

export type SeriesUpdate = {
  title?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['Date']>;
  interval?: Maybe<SeriesInterval>;
  addParticipants?: Maybe<Array<Scalars['String']>>;
  removeParticipants?: Maybe<Array<Scalars['String']>>;
  updateNextMeetingStart?: Maybe<Scalars['Boolean']>;
};

export enum SeriesInterval {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  MonthlyLast = 'MONTHLY_LAST',
  Yearly = 'YEARLY'
}

export type Participant = {
  __typename?: 'Participant';
  user: User;
  socketIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  online?: Maybe<Scalars['Date']>;
  avatar: Avatar;
  isTyping?: Maybe<Scalars['Boolean']>;
};

export type Avatar = {
  __typename?: 'Avatar';
  avatarObject?: Maybe<Scalars['String']>;
  avatarType?: Maybe<AvatarType>;
};

export type AvatarInput = {
  avatarObject?: Maybe<Scalars['String']>;
  avatarType?: Maybe<AvatarType>;
};

export enum AvatarType {
  Emoji = 'EMOJI'
}

export enum MagicLinkType {
  SignupAndRedirect = 'SIGNUP_AND_REDIRECT',
  LoginAndRedirect = 'LOGIN_AND_REDIRECT'
}

export type MagicLinkPayload = {
  __typename?: 'MagicLinkPayload';
  type?: Maybe<MagicLinkType>;
  email?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  targetUrl?: Maybe<Scalars['String']>;
};

export enum MetricType {
  Number = 'NUMBER'
}

export type Metric = {
  __typename?: 'Metric';
  type?: Maybe<MetricType>;
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
};

export type MetricInput = {
  type: MetricType;
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}


export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}

const result: IntrospectionResultData = {
  "__schema": {
    "types": []
  }
};
export default result;
