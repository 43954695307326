import React, {useMemo, useState} from 'react';
import {useQuery} from "@apollo/client";
import {MeetingConfigOption} from "../../graphql/generated/graphql";
import {MEETING_CONFIG_OPTIONS} from "../../statements.graphql";
import ConfiguratorSelection from "./ConfiguratorSelection";
import {Button, Row} from "@geist-ui/react";
import {StepWizardChildProps} from "react-step-wizard";
import {MeetingConfigType} from "./index";


export interface ChooseMeetingTypeProps extends Partial<StepWizardChildProps> {
    setIsReady?: (ready: boolean) => void
    fetchAgenda: () => void
    meetingConfigState: [MeetingConfigType, React.Dispatch<React.SetStateAction<MeetingConfigType>>]
    setTitle: (s: string) => void
}

const ChooseMeetingType = (props: ChooseMeetingTypeProps) => {

    const query = useQuery<{ meetingConfigOptions: MeetingConfigOption[] }>(MEETING_CONFIG_OPTIONS)
    const [selection, setSelection] = props.meetingConfigState

    const next = () => {
        props.fetchAgenda()
        props.setTitle(selection.businessSubject?.label || '')
        props.nextStep!()
    }

    const selectionComplete = useMemo(() => {
        return (selection && Object.keys(selection).length >= 3);
    }, [selection])

    return (
        <div>
            <ConfiguratorSelection selectionState={{selection, setSelection}} query={query}/>
            <Row justify={"space-around"}>
                <Button onClick={next} disabled={!selectionComplete} type={"success"}>Next</Button>
            </Row>
        </div>
    );
};

export default ChooseMeetingType;
