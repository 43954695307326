import React from 'react';
import {Card, Container, Grid, Row, Spacer, Text} from "@geist-ui/react";
import {Series} from "../../graphql/generated/graphql";
import {useHistory} from "react-router";

const DashboardSeries = ({series}: { series: Partial<Series>[] }) => {

    const history = useHistory()

    const openSeries = (seriesId: string) => {
        history.push(`/series/${seriesId}`)
    }

    return (
            <div>
                <Row justify={"space-between"}>
                    <Container align={"middle"}>
                        <Text h2>Series</Text>
                        <Spacer x={0.2}/>
                        <Text small>({series.length || '0'})</Text>
                    </Container>

                </Row>

                <Grid.Container gap={1}>
                    {series.map((s) => {
                        return <Grid key={s.publicId} xs={24} md={8}>
                            <Card hoverable style={{cursor: "pointer"}} onClick={() => openSeries(s.publicId!)}>
                                <Text h4>{s.title}</Text>
                            </Card>
                        </Grid>
                    })}
                </Grid.Container>
            </div>
    );
};

export default DashboardSeries;
