import React, {useEffect} from 'react';
import {useHistory, useParams} from "react-router";
import {Loading} from "@geist-ui/react";

const TokenHandler = () => {

    const params = useParams();
    console.log(params);
    // @ts-ignore
    const {token, base64redirect} = params
    const history = useHistory()

    // localStorage.setItem('token', token)

    useEffect(() => {
        localStorage.setItem('token', token)
        let redirect;
        if (!base64redirect) {
            redirect = '/dashboard'
        } else {
            redirect = Buffer.from(base64redirect, "base64").toString('utf-8')
            console.log(redirect)
            // TODO maybe check token with Backend
        }

        history.push(`${redirect}`, {
            notification: {
                text: 'You successfully logged in.',
                type: 'success'
            }
        })
    }, [token, base64redirect])

    return (
        <Loading/>
    );
};

export default TokenHandler;
