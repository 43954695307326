import React, {useMemo, useState} from 'react';
import {Edit3} from "@geist-ui/react-icons";
import {useTheme} from "@geist-ui/react";

export type EditableContainerProps = {
    children: React.ReactNode
    top?: string,
    left?: string,
    setEditable?: (active: boolean) => void
    alwaysActive?: boolean
    customButton?: any
    customProps?: any
    showDefault?: boolean
}

const EditableContainer = (props: EditableContainerProps) => {

    const theme = useTheme()

    const CustomButton = props.customButton || undefined

    const [show, setShow] = useState(props.showDefault || false)
    const [active, setActive] = useState(false)

    const mouseEnter = () => {
        setShow(true)
    }

    const mouseLeave = () => {
        setShow(false)
    }

    const click = () => {
        props.setEditable && props.setEditable(!active)
        setActive(!active)
    }

    const smaller = useMemo(() => {
        return window.screen.width < 800
    }, [window])

    if (smaller) return (
        <>
            {props.children}
        </>
    )

    return (
        <div style={{position: "relative"}} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
            <div style={{
                position: "absolute",
                paddingRight: '30px',
                top: props.top || '3px',
                left: props.left || '-2.5em',
                opacity: (show || active) ? 0.6 : 0,
                transition: 'opacity 0.35s'
            }}>
                <div style={{cursor: !props.alwaysActive ? "pointer" : "default"}}>
                    {
                        props.customButton ?
                            <CustomButton showing={show || active} {...props.customProps}/>
                            : <div onClick={click}>
                                <Edit3
                                    color={(active || props.alwaysActive) ? theme.palette.success : theme.palette.secondary}/>
                            </div>
                    }
                </div>
            </div>
            {props.children}
        </div>
    );
};

export default EditableContainer;
