import React, {useMemo} from 'react';
import AgendaHeader from "./2-Agenda/AgendaHeader";
import EditableAgenda from "../../components/EditableAgenda";
import {AgendaItem} from "../../graphql/generated/graphql";
import {Button, Row, Spacer} from "@geist-ui/react";
import {StepWizardChildProps} from "react-step-wizard";

export interface CreateEditAgendaProps extends Partial<StepWizardChildProps> {
    agenda: AgendaItem[]
    setAgenda: (agenda: AgendaItem[]) => void
    titleState: [string, (s: string) => void]
}

const CreateEditAgenda = (props: CreateEditAgendaProps) => {

    const {agenda, setAgenda, titleState} = props

    const totalDuration = useMemo(() => {
        if (agenda) {
            return agenda.map(a => a.durationInMinutes).reduce((acc, prev) => acc + prev)
        } else return 0
    }, [agenda])

    return (
        <div>
            <AgendaHeader totalDuration={totalDuration} title={titleState[0]} setTitle={titleState[1]}/>
            <Spacer y={2}/>
            <EditableAgenda agenda={agenda} setAgenda={setAgenda} editable={true}/>
            <Spacer y={2}/>
            <Row justify={"space-around"}><Button type={'success'} onClick={() => props.nextStep!()}>Next</Button></Row>
        </div>
    );
};

export default CreateEditAgenda;
