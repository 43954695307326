import React from 'react';
import {Participant} from "../graphql/generated/graphql";
import {Text} from '@geist-ui/react'

export type ParticipantsAreTypingProps = {
    participants: Partial<Participant>[]
}

const ParticipantsAreTyping = ({participants}: ParticipantsAreTypingProps) => {

    const label = (text: string) => {
        return <Text small type={"secondary"}>{text}</Text>
    }

    if (participants.length === 1) return <>{label(`${participants[0].user?.nickname} is typing ...`)}</>
    if (participants.length === 2) return <>{label(`${participants.map(p => p.user?.nickname).join(' and ')} are typing ...`)}</>

    return (
        <>
            {label(`${participants[0].user?.nickname} and {participants.length-1} other are typing ...`)}
        </>
    );
};

export default ParticipantsAreTyping;
