import React, {useState} from 'react';
import {Metric, MetricInput, MetricType} from "../../../graphql/generated/graphql";
import {Button, Card, Input, Row, Text, useClickAway} from "@geist-ui/react";
import {ArrowDown, ArrowUp, Send} from "@geist-ui/react-icons";
import {useForm} from "react-hook-form";
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup';

export type MetricProps = {
    metric: Metric & { growth: number | undefined }
    updateMetric: (metric: MetricInput) => Promise<unknown>
}

const schema = yup.object().shape({
    value: yup.string().required("Please enter a number").matches(RegExp('^-?\\d*[\.\,]?\\d+[$%€]?$'), 'Input must be number with optional suffix ($,%,€), e.g 324.53%') // TODO: split regex into smaller parts
})

const MetricElement = ({metric, updateMetric}: MetricProps) => {

    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(metric.value)

    const {register, handleSubmit, errors} = useForm<{ value: string }>({
        resolver: yupResolver(schema)
    })

    const ref = React.useRef<HTMLDivElement>(null)
    useClickAway(ref, () => {
        if (editing) {
            setEditing(false)
        }
    })

    const upMetric = () => {
        const valueMatch = value!.match(RegExp('^-?\\d*[\.\,]?\\d+'))
        const suffixMatch = value!.match(RegExp('[$%€]'))
        updateMetric({
            type: metric.type as MetricType,
            key: String(metric.key),
            value: String(valueMatch).replace(',', '.'),
            suffix: suffixMatch ? suffixMatch[0] : undefined
        }).then(() => setEditing(false))
    }

    const _test = () => {
        const match = value!.match(RegExp('^-?\\d*[\.\,]?\\d+'))
        console.log(Number(String(match).replace(',', '.')))
        console.log(value!.match(RegExp('[$%€]')) || 'not found')
    }


    return (
        <Card shadow style={{height: '120px', maxWidth: '300px', minWidth: '240px', margin: '0 10px'}}>
            <Text small>{metric.key}</Text>
            {
                editing ?
                    <div ref={ref as unknown as any}>
                        <form onSubmit={handleSubmit(upMetric)}>
                            <Row>
                                <Input ref={register({required: "Please enter a number"})} name={'value'} size={"small"}
                                       step={0.01} initialValue={metric.value || undefined}
                                       onChange={(e) => setValue(e.target.value)}/>
                                <Button style={{marginLeft: '5px'}} auto size={'small'} icon={<Send/>}
                                        onClick={handleSubmit(upMetric)}/>
                            </Row>
                            {errors.value && <Text small type={"error"} size={10}>{errors.value.message}</Text>}
                        </form>
                    </div>
                    : <Text style={{marginBottom: 0}} h3
                            onClick={() => setEditing(true)}>{
                        metric.value ? Number(metric.value).toLocaleString() + (metric.suffix ? metric.suffix : '') : "click to enter"
                    }</Text>

            }
            {metric.value && metric.growth && <>
                <Text style={{color: metric.growth! > 0 ? "green" : "red"}} b small>
                    {metric.growth > 0 ? <ArrowUp size={14}/> : <ArrowDown size={14}/>} {metric.growth.toFixed(2)}%
                </Text>
                <Text small type={"secondary"}> vs last meeting</Text>
            </>
            }
        </Card>
    );
};

export default MetricElement;
