import {Card, Loading, Spacer, Text} from "@geist-ui/react";
import React, {Fragment} from "react";
import {MeetingConfigOption, MeetingConfigOptionItem} from "../../graphql/generated/graphql";
import RenderOptions from "./RenderOptions";
import update from 'immutability-helper'
import {QueryResult} from "@apollo/client";
import {MeetingConfigType} from "./index";


type ConfiguratorSelectionProps = {
    selectionState: { selection: MeetingConfigType, setSelection: (selection: MeetingConfigType) => void }
    query: QueryResult<{ meetingConfigOptions: MeetingConfigOption[]; }, Record<string, any>>
}

const ConfiguratorSelection = ({selectionState, query}: ConfiguratorSelectionProps) => {


    const {selection, setSelection} = selectionState

    const {loading, error, data} = query;

    const selectOption = (identifier: string, option: MeetingConfigOptionItem) => {
        if (identifier in selection && selection[identifier]!.value === option.val) {
            setSelection(update(selection, {$unset: [identifier]}))
            return
        } else {
            setSelection(update(selection, {[identifier]: {$set: {value: option.val, label: option.label}}}))
        }
    }

    if (loading) return <Loading/>
    if (error) return <Text type={'error'}>{error.message}</Text>


    return (
        <Fragment>
            {data && data.meetingConfigOptions.map((config) => {
                return (
                    <>
                        <Card type={"lite"} key={config.identifier}>
                            <Text h4>{config.label}</Text>
                            <RenderOptions options={config.options} identifier={config.identifier} selection={selection}
                                           selectOption={selectOption}/>
                        </Card>
                        <Spacer y={1}/>
                    </>
                )
            })}
        </Fragment>
    )
}


export default ConfiguratorSelection
