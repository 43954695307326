import React, {useState} from 'react';
import {Button, Card, Col, Popover, Row, Text} from "@geist-ui/react";
import {AgendaItem as AgendaItemType} from "../../../graphql/generated/graphql";
import {Delete, Edit, Edit2, MoreVertical} from "@geist-ui/react-icons";

export type AgendaItemProps = {
    item: AgendaItemType
    setEditingItem: (item: AgendaItemType) => void
    deleteItem: () => void
    setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
    isEditable: boolean | undefined
}

const AgendaItem = ({item, setEditingItem, setModalVisible, deleteItem, isEditable}: AgendaItemProps) => {

    const [visible, setVisible] = useState(false)
    const changeHandler = (next: boolean) => {
        setVisible(next)
    }
    const popoverContent = () => {
        return (
            <>
                <Popover.Item>
                    <Button size={"small"} icon={<Edit/>} onClick={() => showEditingModal()}>Edit</Button>
                </Popover.Item>
                <Popover.Item>
                    <Button size={"small"} icon={<Delete/>} type={"error"} onClick={deleteItem}>Delete</Button>
                </Popover.Item>
            </>
        )
    }

    const showEditingModal = () => {
        setEditingItem({
            topic: item.topic,
            durationInMinutes: +item.durationInMinutes
        })
        setModalVisible(true)
        setVisible(false)
    }

    return (
        <>
            <Card hoverable={isEditable || false}>
                <Row align={'middle'} justify={'space-between'}>
                    <Col span={1}>{isEditable && <MoreVertical size={13} color={'grey'}/>}</Col>
                    <Col span={17}><Text small>{item.topic}</Text></Col>
                    <Col span={4}><Text b>{item.durationInMinutes}</Text><Text
                        small>min</Text></Col>
                    <Col span={1}>
                        {
                            isEditable &&
                            <Popover onVisibleChange={changeHandler} visible={visible} content={popoverContent}
                                     style={{cursor: 'pointer'}}>
                                <Edit2 size={13} color={'grey'}/>
                            </Popover>
                        }
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default AgendaItem;
