import {Col, Input, Modal, Row, useInput} from "@geist-ui/react";
import React, {useEffect} from "react";
import {AgendaItem} from "../../../graphql/generated/graphql";


type Props = {
    bindings: { open: boolean, onClose: () => void }
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    createItem: (item: AgendaItem) => void
    updateItem: (index: number, item: AgendaItem) => void
    editing: { index: number, item: AgendaItem } | undefined
}

const AgendaItemModal = ({bindings, setVisible, createItem, updateItem, editing}: Props) => {

    const {index, item} = editing || {}

    const {state: topicState, setState: setTopicState, reset: topicReset, bindings: topicBindings} = useInput('')
    const {state: durationState, setState: setDurationState, reset: durationReset, bindings: durationBindings} = useInput('')

    const submit = () => {
        if (index !== undefined) {
            updateItem(index, {topic: topicState, durationInMinutes: +durationState})
        } else {
            createItem({topic: topicState, durationInMinutes: +durationState})
        }
        setVisible(false)
        topicReset()
        durationReset()
    }

    const onkeydown = (event: React.KeyboardEvent<any>) => {
        if (event.key === "Enter") {
            submit()
        }
    }


    useEffect(() => {
        if (item) {
            setTopicState(item.topic)
            setDurationState(String(item.durationInMinutes))
        } else {
            setTopicState('')
            setDurationState('5')
        }
    }, [item])

    return (
        <Modal {...bindings}>
            <Modal.Title>{item ? 'Edit' : 'Create'} Agenda Item</Modal.Title>
            <Modal.Content>
                <Row justify={"space-between"}>
                    <Col span={16}>
                        <Input autoFocus width={'100%'} {...topicBindings} onKeyDown={onkeydown}>Topic</Input>
                    </Col>
                    <Col span={7}>
                        <Input labelRight={'min'} width={'100%'}
                               onKeyDown={onkeydown} {...durationBindings}>Duration</Input>
                    </Col>
                </Row>
            </Modal.Content>
            <Modal.Action passive onClick={() => setVisible(false)}>Cancel</Modal.Action>
            <Modal.Action onClick={() => {
                submit() /* TODO enforce number input */
            }}>Submit</Modal.Action>
        </Modal>
    )
}

export default AgendaItemModal
