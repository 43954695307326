import React, {useMemo} from 'react';
import {Select} from "antd";

export type SelectParticipantsProps = {
    participantSuggestions: string[]
    setParticipants: (participants: string[]) => void
    selectedParticipants?: string[]
}

const SelectParticipants = (props: SelectParticipantsProps) => {

    const handleParticipantEmailSelect = (value: string[], _option: any) => {
        props.setParticipants(value)
    }

    const options = useMemo(() => {
        return props.participantSuggestions.map(p => ({value: p, label: p}))
    }, [props.participantSuggestions])

    return (
        <Select placeholder={"Invite participants by email"}
                mode="tags" style={{width: '100%'}}
                onChange={handleParticipantEmailSelect}
                tokenSeparators={[',', ' ']}
                options={options}
                defaultValue={props.selectedParticipants}
                notFoundContent={null}
        >
        </Select>
    );
};

export default SelectParticipants;
