import React, {RefObject, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Grid, Popover, Row, Textarea, useInput} from "@geist-ui/react";
import {useKeyPress} from "ahooks";
import {MeetingEvent, MeetingEventInput, MeetingEventType, Participant} from "../../../graphql/generated/graphql";
import {Calendar as CalendarIcon, CheckSquare, FileText, Pin, Send} from "@geist-ui/react-icons";
import Calendar from 'react-calendar'
import Select, {Theme} from 'react-select';
import log from 'loglevel';

import 'react-calendar/dist/Calendar.css';
import _ from 'lodash';
import jwt from "jsonwebtoken";
import ParticipantsAreTyping from "../../../components/ParticipantsAreTyping";

export type MeetingInputProps = {
    addEvent: (event: Pick<MeetingEventInput, 'type' | 'textBody'> | Pick<MeetingEventInput, 'type' | 'textBody' | 'dueDate' | 'responsible'>) => void
    editing: [(MeetingEvent | null), React.Dispatch<React.SetStateAction<(MeetingEvent | null)>>]
    updatePost: (post: MeetingEventInput) => void
    participants: Participant[]
    setIsTyping: (typingStatus: boolean) => void
}

type Type = 'NOTE' | 'DECISION' | 'TASK'

const ChangeTypeButton = ({icon, buttonType, currentType, setType}: { icon: React.ReactNode, buttonType: Type, currentType: Type, setType: (type: Type) => void }) => {
    return (<Button style={{marginRight: '1em'}} icon={icon} size={'small'} auto
                    type={buttonType === currentType ? 'secondary' : 'default'}
                    onClick={() => {
                        setType(buttonType)
                    }}
    >{buttonType}</Button>)
}

const MeetingInput = ({addEvent, editing, updatePost, participants, setIsTyping}: MeetingInputProps) => {

    const [editingPost] = editing
    const {state, reset, bindings, setState} = useInput('')

    // test for word character or emoji
    const regex = /\w|(?:[\u00A9\u00AE\u203C\u2049\u2122\u2139\u2194-\u2199\u21A9-\u21AA\u231A-\u231B\u2328\u23CF\u23E9-\u23F3\u23F8-\u23FA\u24C2\u25AA-\u25AB\u25B6\u25C0\u25FB-\u25FE\u2600-\u2604\u260E\u2611\u2614-\u2615\u2618\u261D\u2620\u2622-\u2623\u2626\u262A\u262E-\u262F\u2638-\u263A\u2640\u2642\u2648-\u2653\u2660\u2663\u2665-\u2666\u2668\u267B\u267F\u2692-\u2697\u2699\u269B-\u269C\u26A0-\u26A1\u26AA-\u26AB\u26B0-\u26B1\u26BD-\u26BE\u26C4-\u26C5\u26C8\u26CE-\u26CF\u26D1\u26D3-\u26D4\u26E9-\u26EA\u26F0-\u26F5\u26F7-\u26FA\u26FD\u2702\u2705\u2708-\u270D\u270F\u2712\u2714\u2716\u271D\u2721\u2728\u2733-\u2734\u2744\u2747\u274C\u274E\u2753-\u2755\u2757\u2763-\u2764\u2795-\u2797\u27A1\u27B0\u27BF\u2934-\u2935\u2B05-\u2B07\u2B1B-\u2B1C\u2B50\u2B55\u3030\u303D\u3297\u3299]|(?:\uD83C[\uDC04\uDCCF\uDD70-\uDD71\uDD7E-\uDD7F\uDD8E\uDD91-\uDD9A\uDDE6-\uDDFF\uDE01-\uDE02\uDE1A\uDE2F\uDE32-\uDE3A\uDE50-\uDE51\uDF00-\uDF21\uDF24-\uDF93\uDF96-\uDF97\uDF99-\uDF9B\uDF9E-\uDFF0\uDFF3-\uDFF5\uDFF7-\uDFFF]|\uD83D[\uDC00-\uDCFD\uDCFF-\uDD3D\uDD49-\uDD4E\uDD50-\uDD67\uDD6F-\uDD70\uDD73-\uDD7A\uDD87\uDD8A-\uDD8D\uDD90\uDD95-\uDD96\uDDA4-\uDDA5\uDDA8\uDDB1-\uDDB2\uDDBC\uDDC2-\uDDC4\uDDD1-\uDDD3\uDDDC-\uDDDE\uDDE1\uDDE3\uDDE8\uDDEF\uDDF3\uDDFA-\uDE4F\uDE80-\uDEC5\uDECB-\uDED2\uDEE0-\uDEE5\uDEE9\uDEEB-\uDEEC\uDEF0\uDEF3-\uDEF6]|\uD83E[\uDD10-\uDD1E\uDD20-\uDD27\uDD30\uDD33-\uDD3A\uDD3C-\uDD3E\uDD40-\uDD45\uDD47-\uDD4B\uDD50-\uDD5E\uDD80-\uDD91\uDDC0]))/ // check that state (input) is not empty and not only a line break; contains min a word character

    const [noteType, setNoteType] = useState<any>(MeetingEventType.Note)
    const [dueDate, setDueDate] = useState<Date | undefined>(undefined)
    const [responsible, setResponsible] = useState<Participant>()

    const customStyles = {
        control: (provided: any, _state: any) => ({
            width: 160,
            ...provided
        })
    }

    const customTheme = (theme: Theme) => ({
        ...theme,
        spacing: {
            baseUnit: 2,
            controlHeight: 32,
            menuGutter: 4
        }
    })


    function onDueDateChange(nextValue: any) {
        setDueDate(nextValue);
    }

    const selectParticipant = (participant: any, _actionMeta: any) => {
        setResponsible(participant)
        log.debug('Participants', participant)
    }

    const updateWritingStatus = () => {
        debouncedSetWritingTrue()
        debouncedSetWritingFalse()
    }

    const setTypingTrue = () => {
        setIsTyping(true)
    }

    const setTypingFalse = () => {
        setIsTyping(false)
    }

    const debouncedSetWritingTrue = useCallback(_.debounce(setTypingTrue, 500, {trailing: false, leading: true}), [])
    const debouncedSetWritingFalse = useCallback(_.debounce(setTypingFalse, 500, {trailing: true, leading: false}), [])

    const send = () => {
        if (!regex.test(state)) return
        if (editingPost !== null) {
            updatePost({...(editingPost as MeetingEventInput), textBody: state})
        } else {
            let post: any = {
                type: noteType,
                textBody: state,
            }
            switch (noteType) {
                case MeetingEventType.Task:
                    post = {...post, dueDate: dueDate}
                    if (responsible) {
                        post = {...post, responsible: responsible.user.email}
                    }
            }
            log.debug("sending post", post)
            addEvent(post)
        }
        reset()
        inputRef && inputRef.current && inputRef.current.focus()
    }

    const inputRef = useKeyPress('enter', ((event: KeyboardEvent) => {
        if (!event.shiftKey) {
            event.preventDefault()
            send()
        }
    })) as unknown as RefObject<HTMLTextAreaElement>

    const currentUserEmail = useMemo(() => {
        return (jwt.decode(localStorage.getItem('token')!) as any).email
    }, [])

    const writingParticipants = useMemo(() => {
        return participants.filter(p => p.user.email != currentUserEmail).filter(p => p.isTyping).filter(p => p.online)
    }, [participants])

    useEffect(() => {
        if (editingPost != null) {
            setState(editingPost.textBody!)
        }
    }, [editingPost])


    // @ts-ignore
    return (
        <Grid.Container gap={0.5} direction={"column"}>
            <Grid direction={"row"} xs={24}>
                <Row style={{height: '40px'}}>
                    <ChangeTypeButton icon={<FileText/>} buttonType={MeetingEventType.Note} currentType={noteType}
                                      setType={setNoteType}/>
                    <ChangeTypeButton icon={<CheckSquare/>} buttonType={MeetingEventType.Task} currentType={noteType}
                                      setType={setNoteType}/>
                    <ChangeTypeButton icon={<Pin/>} buttonType={MeetingEventType.Decision} currentType={noteType}
                                      setType={setNoteType}/>
                    {noteType === MeetingEventType.Task && (
                        <>
                            <Popover placement={"top"} content={<Calendar minDate={new Date()} value={dueDate}
                                                                          onChange={onDueDateChange}/>}>
                                <Button style={{marginRight: '1em'}} icon={<CalendarIcon/>} auto type={"warning"}
                                        size={"small"}>
                                    {dueDate ? dueDate.toLocaleDateString() : 'Due date'}
                                </Button>
                            </Popover>
                            <Select styles={customStyles}
                                    theme={customTheme}
                                    placeholder={'Responsible'}
                                    options={participants.map(p => ({
                                        ...p,
                                        label: p.avatar.avatarObject + ' ' + p.user.nickname,
                                        value: p.user.userId
                                    }))}
                                    onChange={selectParticipant}
                                    isSearchable/>
                        </>
                    )
                    }
                </Row>
            </Grid>
            <Grid xs={24}>
                <div style={{position: "relative"}}>
                    <Textarea style={{background: '#fff', overflow: 'hidden', borderRadius: '5px'}}
                              ref={inputRef}
                              onInput={updateWritingStatus}
                              width={'100%'}
                              placeholder={'Enter your text here'}
                              {...bindings}/>
                    <div style={{position: "absolute", right: '10px', bottom: '10px'}}><Button icon={<Send/>} auto
                                                                                               size={"small"}
                                                                                               onClick={send}/></div>
                </div>
                {writingParticipants.length > 0 &&
                <ParticipantsAreTyping participants={writingParticipants}/>
                }
            </Grid>
        </Grid.Container>
    );
};

export default MeetingInput;
