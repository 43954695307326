import {gql} from "@apollo/client";


/* Agenda */

export const GET_AGENDA = gql`
    query GetAgenda($businessSubject: String!, $numberOfParticipants: String!, $durationInMinutes: String!) {
        getAgenda(businessSubject: $businessSubject, durationInMinutes: $durationInMinutes, numberOfParticipants: $numberOfParticipants) {
            topic
            durationInMinutes
        }
    }
`


/* Meeting Statement */

export const MEETING_QUERY = gql`
    query Meeting($publicId: ID!){
        meeting(publicId: $publicId) {
            title
            publicId
            start
            metrics {
                type
                key
                value
                suffix
            }
            agenda {
                topic
                durationInMinutes
            }
            meetingEvents {
                type
                textBody
                dueDate
                hash
                agendaItemId
                author {
                    userId
                    nickname
                }
                responsible {
                    nickname
                }
                authorAvatar {
                    avatarObject
                    avatarType
                }
            }
            participants {
                user {
                    email
                    userId
                    nickname
                }
                online
                avatar {
                    avatarObject
                    avatarType
                }
                isTyping
            }
            previous {
                publicId
                metrics {
                    type
                    key
                    value
                    suffix
                }
            }
            created
            started
            closed
        }
    }
`
export const HEARTBEAT_MUTATION = gql`
    mutation UpdateOnlineStatus($publicId: ID!) {
        updateOnlineStatus(publicId: $publicId)
    }
`
export const MEETING_START_MUTATION = gql`
    mutation StartMeeting($publicId: ID!){
        startMeeting(publicId: $publicId)
    }
`;
export const MEETING_CREATE = gql`
    mutation CreateMeeting($meetingInput: MeetingInput!){
        createMeeting(meetingInput: $meetingInput) {
            publicId
        }
    }
`

export const ADD_OR_UPDATE_METRIC = gql`
    mutation AddOrUpdateMetric($publicId: ID!, $metric: MetricInput!) {
        addOrUpdateMetric(publicId: $publicId, metric: $metric)
    }
`

export const SERIES_CREATE = gql`
    mutation CreateSeries($seriesInput: SeriesInput!){
        createSeries(seriesInput: $seriesInput) {
            publicId
        }
    }
`
export const USER_RELATED_EMAILS = gql`
    query UserRelatedEmails {
        user {
            userId
            meetings {
                publicId
                participants {
                    user {
                        userId
                        email
                    }
                }
            }
        }
    }
`
export const MEETING_CLOSE = gql`
    mutation CloseMeeting($publicId: ID!) {
        closeMeeting(publicId: $publicId)
    }
`
export const MEETING_CHANGE_CURRENT_TOPIC = gql`
    mutation MeetingCurrentTopic($publicId: ID!, $direction: TopicUpdateDirection!) {
        updateCurrentTopic(publicId: $publicId, direction: $direction)
    }
`
export const SUMMARY_QUERY = gql`
    query Meeting($publicId: ID!){
        meeting(publicId: $publicId) {
            publicId
            title
            previous {
                publicId
                metrics {
                    type
                    value
                    key
                    suffix
                }
            }
            invitees {
                email
            }
            participants {
                user {
                    nickname
                    userId
                }
                avatar {
                    avatarObject
                    avatarType
                }
            }
            metrics {
                type
                key
                value
                suffix
            }
            agenda {topic, durationInMinutes}
            summary {
                tasks {
                    objects {
                        textBody,
                        agendaItemId,
                        hash
                        dueDate,
                        done,
                        responsible {
                            nickname,
                            userId
                        },
                        author {
                            nickname }
                    }
                }
                notes {objects {textBody, agendaItemId}}
                decisions {objects {textBody, agendaItemId}}
            }
        }
    }
`
export const TASK_SET_DONE = gql`
    mutation setTaskDone($publicId: ID!, $hash: String!, $done: Boolean!) {
        setTaskDone(publicId: $publicId, hash: $hash, done: $done)
    }
`
export const TASK_SUBSCRIPTION = gql`
    subscription TaskSubscription($publicId: ID!) {
        taskStateUpdate(publicId: $publicId) {
            hash
            done
            dueDate
        }
    }
`

export const SET_TYPING_STATUS = gql`
    mutation SetTypingStatus($publicId: ID, $typingStatus: Boolean) {
        setTypingStatus(publicId: $publicId, typingStatus: $typingStatus)
    }
`

export const ARCHIVE_MEETING = gql`
    mutation ArchiveMeeting($meetingId: ID) {
        archiveMeeting(meetingId: $meetingId) {
            userId
        }
    }
`

export const UPDATE_MEETING_SETTINGS = gql`
    mutation UpdateMeetingSettings($publicId: ID!, $meetingSettingsUpdate: MeetingSettingsUpdateInput!) {
        updateMeetingSettings(publicId: $publicId, meetingSettingsUpdate: $meetingSettingsUpdate)
    }
`

export const FOLLOWUP_QUERY = gql`
    query Meeting($publicId: ID) {
        meeting(publicId: $publicId) {
            publicId
            title
            agenda {
                topic
                durationInMinutes
            }
        }
    }
`

/* Posts Statements */

export const POST_ADD_MUTATION = gql`
    mutation AddEventParticipant($publicId: ID!, $meetingEvent: MeetingEventInput!) {
        addEventToMeeting(publicId: $publicId, meetingEvent: $meetingEvent)
    }
`
export const POST_UPDATE_MUTATION = gql`
    mutation UpdatePost($publicId: ID!, $post: MeetingEventInput!) {
        updatePost(publicId: $publicId, post: $post)
    }
`
export const POST_NEW_SUBSCRIPTION = gql`
    subscription MeetingEventsSub($publicId: ID!) {
        postAdded(publicId: $publicId) {
            type
            hash
            dueDate
            author {
                userId
                nickname
            }
            authorAvatar {
                avatarObject
                avatarType
            }
            responsible {
                nickname
            }
            textBody
            agendaItemId
        }
    }
`
export const POSTS_UPDATE_SUBSCRIPTION = gql`
    subscription PostUpdateSub($publicId: ID!) {
        postUpdate(publicId: $publicId) {
            type
            textBody
            hash
        }
    }
`
export const POST_DELETE = gql`
    mutation DeletePost($publicId: ID!, $hash: String!) {
        deletePost(publicId: $publicId, hash: $hash)
    }
`
export const POST_DELETED_SUBSCRIPTION = gql`
    subscription PostDeleted($publicId: ID!) {
        postDeleted(publicId: $publicId) #return hash string
    }
`

/* Participants Statements */

export const PARTICIPANT_ADD_MUTATION = gql`
    mutation AddParticipant($publicId: ID!) {
        addParticipantToMeeting(
            publicId: $publicId
        ) {
            user {
                userId
                nickname
                email
            }
            online
            avatar {
                avatarObject
                avatarType
            }
        }
    }
`
export const MEETING_UPDATED_SUBSCRIPTION = gql`
    subscription ParticipantsSub($publicId: ID!) {
        meetingUpdated(publicId: $publicId) {
            publicId
            metrics {
                type
                value
                key
                suffix
            }
            participants{
                user {
                    userId
                    nickname
                    email
                }
                online
                isTyping
                avatar {
                    avatarObject
                    avatarType
                }
            }
        }
    }
`


/* User Statements */

export const DASHBOARD_QUERY = gql`
    query User {
        user {
            userId
            email
            firstName
            lastName
            nickname
            series {
                title
                publicId
            }
            meetings {
                title
                publicId
                start
                started
                closed
                series {
                    publicId
                    title
                }
                previous {
                    publicId
                    title
                }
                participants {
                    user {
                        email
                        nickname
                        userId
                    }
                    online
                    avatar {
                        avatarObject
                        avatarType
                    }
                }
            }
        }
    }
`

export const USER_NICKNAME_QUERY = gql`
    query User {
        user {
            userId
            nickname
        }
    }
`

export const USER_LOGIN = gql`
    mutation Login($email: String!) {
        login(email: $email)
    }
`

export const USER_CREATE = gql`
    mutation Create($email: String!, $nickname: String!) {
        createUser(email: $email, nickname: $nickname) {
            userId
        }
    }
`

export const MEETING_CONFIG_OPTIONS = gql`
    query {
        meetingConfigOptions {
            identifier,
            label,
            options {
                label,
                graphic,
                type,
                val
            }
        }
    }
`

export const SERIES_QUERY = gql`
    query SeriesQuery($seriesId: ID!) {
        series(publicId: $seriesId) {
            title
            interval
            start
            participants {
                userId
                nickname
                email
            }
            meetings {
                publicId
                title
                closed
                start
                started
                previous {
                    publicId
                    title
                }
                summary {
                    decisions {
                        objects {
                            textBody
                        }
                    }
                    tasks { objects {
                        hash
                        responsible {
                            userId
                            nickname
                            email
                        }
                        textBody
                        done
                    }}
                }
            }
        }
    }

`
export const SERIES_UPDATE = gql`
    mutation SeriesUpdate($publicId: ID!, $update: SeriesUpdate!) {
        updateSeries(publicId: $publicId, update: $update) {
            publicId
        }
    }
`
