import React, {useEffect, useState} from 'react';
import {Modal, useModal, useToasts} from "@geist-ui/react";
import {ToastAction} from "@geist-ui/react/dist/use-toasts/use-toast";
import {useHistory} from "react-router";
import log from "loglevel";
import {DatePicker, Form, Input} from "antd";
import moment from "moment";
import SelectParticipants from "../../components/SelectParticipants";
import {Meeting} from "../../graphql/generated/graphql";
import {useForm} from "antd/es/form/Form";
import {dateValidator, disablePastDates} from "../1-Create/MeetingOptions";

export type CreateFollowupModalProps = {
    modal: ReturnType<typeof useModal>
    createFollowup: (title: string, start: Date, invitees: string[]) => Promise<string>
    meeting: Partial<Meeting>
}

const CreateFollowupModal = ({modal, createFollowup, meeting}: CreateFollowupModalProps) => {

    const [, setToast] = useToasts()
    const history = useHistory()
    const [form] = useForm()


    const [loading, setLoading] = useState<boolean>(false)
    const [invitees, setInvitees] = useState()

    const submitFollowup = () => {
        setLoading(true)
        createFollowup(form.getFieldValue('title'), form.getFieldValue('start-date').toDate(), invitees)
            .then((meetingId: string) => {
                modal.setVisible(false)
                setLoading(false)

                const toastAction: ToastAction = {
                    name: 'Meeting',
                    passive: true,
                    handler: (ev, cancel) => {
                        history.push('/meeting/' + meetingId)
                        cancel()
                    }

                }

                setToast({
                    type: 'success',
                    text: "Yes, the follow-up meeting was created.",
                    actions: [toastAction]
                })
            })
    }

    const submit = () => {
        form.validateFields()
            .then(() => submitFollowup())
            .catch(res => log.error(res))
    }

    useEffect(() => {
        setInvitees(meeting.invitees?.map(i => i!.email) as string[])
    }, [meeting.invitees])

    return (
        <Modal {...modal.bindings}>
            <Modal.Title>Create a Followup meeting</Modal.Title>
            <Modal.Subtitle>
                Choose a start time and title
            </Modal.Subtitle>
            <Modal.Content>
                <Form form={form}>
                    <Form.Item name={'title'} label={"Title"} rules={[{required: true}]}
                               initialValue={'Follow up: ' + meeting.title}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={'start-date'} label={"Start date"}
                               rules={[{type: 'object', required: true, validator: dateValidator}]}
                               initialValue={moment().add(1, 'day')}>
                        <DatePicker
                            disabledDate={disablePastDates}
                            style={{width: '100%'}}
                            placeholder={'Select start date'}
                            showTime={{
                                defaultValue: moment('10:00', 'HH:mm'),
                                minuteStep: 5,
                                format: 'HH:mm'
                            }}
                            format='YYYY/MM/DD HH:mm'
                        />
                    </Form.Item>
                    <Form.Item label={"Participants"}>
                        <SelectParticipants participantSuggestions={invitees}
                                            selectedParticipants={invitees}
                                            setParticipants={setInvitees}/>
                    </Form.Item>
                </Form>
            </Modal.Content>
            <Modal.Action passive onClick={() => modal.setVisible(false)}>Cancel</Modal.Action>
            <Modal.Action onClick={submit} loading={loading}>Create</Modal.Action>
        </Modal>
    );
};

export default CreateFollowupModal;
