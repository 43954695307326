import React, {useEffect, useState} from "react";
import {Page, Spacer, Text} from "@geist-ui/react";
import Header from "../../Header";
import StepWizard from "react-step-wizard";
import ChooseMeetingType from "./ChooseMeetingType";
import {useLazyQuery, useMutation} from "@apollo/client";
import {GET_AGENDA, MEETING_CREATE, SERIES_CREATE} from "../../statements.graphql";
import {
    AgendaItem,
    BusinessSubject,
    Mutation,
    MutationCreateMeetingArgs,
    MutationCreateSeriesArgs,
    QueryGetAgendaArgs,
    SeriesInterval
} from "../../graphql/generated/graphql";
import CreateEditAgenda from "./CreateEditAgenda";
import StepperNav from "./StepperNav";
import MeetingOptions from "./MeetingOptions";
import {useHistory, useLocation} from "react-router";

export type MeetingConfigType = {
    [key: string]: { value: string, label: string } | undefined,
}

export const StepperLabels = [
    'Meeting type', 'Agenda', 'Options'
]

const Create = () => {

    const history = useHistory()
    const location = useLocation()

    const meetingConfigState = useState<MeetingConfigType>({})

    const [queryAgenda, agendaQueryResult] = useLazyQuery<{ getAgenda: AgendaItem[] }, QueryGetAgendaArgs>(GET_AGENDA)
    const [createMeetingMutation] = useMutation<{ createMeeting: Mutation['createMeeting'] }, MutationCreateMeetingArgs>(MEETING_CREATE);
    const [createSeriesMutation] = useMutation<{ createSeries: Mutation['createSeries'] }, MutationCreateSeriesArgs>(SERIES_CREATE)

    const [prevMeeting] = useState<{ id: string, title: string } | undefined>(location.state ? {
        id: (location.state as any).prev,
        title: (location.state as any).prevTitle
    } || undefined : undefined)


    const [agenda, setAgenda] = useState()
    const titleState = useState<string>('')
    const [meetingStartDate, setMeetingStartDate] = useState<Date>(new Date)
    const [participantEmails, setParticipantEmails] = useState<string[]>([])
    const [isSeries, setIsSeries] = useState<boolean>(false)
    const [interval, setInterval] = useState<SeriesInterval | undefined>(undefined)

    const fetchAgenda = () => {
        queryAgenda({
            variables: {
                businessSubject: meetingConfigState[0]!.businessSubject?.value!,
                durationInMinutes: meetingConfigState[0]!.durationInMinutes?.value!,
                numberOfParticipants: meetingConfigState[0]!.numberOfParticipants?.value!
            }
        })
    }
    const createMeeting = () => {
        if (isSeries && interval) {
            createSeriesMutation({
                variables: {
                    seriesInput: {
                        title: titleState[0],
                        agenda: agenda,
                        meetingConfig: {
                            businessSubject: meetingConfigState[0]!.businessSubject?.value! as BusinessSubject,
                            durationInMinutes: meetingConfigState[0]!.durationInMinutes?.value!,
                            numberOfParticipants: meetingConfigState[0]!.numberOfParticipants?.value!
                        },
                        participantEmails,
                        start: meetingStartDate,
                        interval,
                        previousPublicId: prevMeeting?.id || undefined
                    }
                }
            }).then(res => {
                history.push('/series/' + res.data!.createSeries?.publicId)
            })
        } else {
            createMeetingMutation({
                variables: {
                    meetingInput: {
                        title: titleState[0],
                        agenda: agenda,
                        invitees: participantEmails,
                        meetingConfig: {
                            businessSubject: meetingConfigState[0]!.businessSubject?.value! as BusinessSubject,
                            durationInMinutes: meetingConfigState[0]!.durationInMinutes?.value!,
                            numberOfParticipants: meetingConfigState[0]!.numberOfParticipants?.value!
                        },
                        start: meetingStartDate,
                        previousPublicId: prevMeeting?.id || undefined
                    }
                }
            }).then(res => {
                history.push('/meeting/' + res.data!.createMeeting?.publicId)
            })
        }
    }

    useEffect(() => {
        if (agendaQueryResult.data) {
            setAgenda(agendaQueryResult.data.getAgenda.map(a => ({
                topic: a.topic,
                durationInMinutes: a.durationInMinutes
            })))
        }
    }, [agendaQueryResult])


    return (
        <Page>
            <Header headerTitle={"Create"}/>

            <Page.Content>
                {prevMeeting ?
                    <><Text small>Follow up for </Text><Text small b>{prevMeeting.title}</Text><Spacer y={2}/></>
                    : null
                }
                <StepWizard nav={<StepperNav/>} isLazyMount={true}>
                    <ChooseMeetingType meetingConfigState={meetingConfigState} fetchAgenda={fetchAgenda}
                                       setTitle={titleState[1]}/>
                    <CreateEditAgenda agenda={agenda} setAgenda={setAgenda} titleState={titleState}/>
                    <MeetingOptions title={titleState[0]}
                                    setMeetingStartDate={setMeetingStartDate}
                                    setInterval={setInterval}
                                    setParticipants={setParticipantEmails}
                                    isSeries={isSeries}
                                    setIsSeries={setIsSeries}
                                    onSubmit={createMeeting}
                    />
                </StepWizard>

            </Page.Content>

        </Page>
    )
}

export default Create;
