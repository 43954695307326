import React, {Fragment} from "react";
import {Draggable} from "react-beautiful-dnd";
import {AgendaItem as AgendaItemType} from "../../../graphql/generated/graphql";
import AgendaItem from "./AgendaItem";

const grid = 2;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // styles we need to apply on draggable
    ...draggableStyle
});

type AgendaItemsRenderedProps = {
    items: AgendaItemType[],
    setEditing: (index: number, item: AgendaItemType) => void
    setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
    deleteItem: any
    isEditable: boolean | undefined
}

const AgendaItemsRendered = ({items, setEditing, setModalVisible, deleteItem, isEditable}: AgendaItemsRenderedProps) => {
    return (
        <Fragment>
            {items.map((item, index) => (
                <Draggable key={item.topic} draggableId={item.topic!} index={index}
                           isDragDisabled={!isEditable || false}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                            <AgendaItem item={item} setModalVisible={setModalVisible}
                                        deleteItem={() => deleteItem(index)}
                                        setEditingItem={(item) => setEditing(index, item)} isEditable={isEditable}/>
                        </div>
                    )}
                </Draggable>
            ))}
        </Fragment>
    )
}

export default AgendaItemsRendered;
