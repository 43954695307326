import React, {useEffect, useState} from 'react';
import {Badge, Button, Col, Container, Modal, Page, Row, Spacer, Text, useModal} from "@geist-ui/react";
import {useMutation} from "@apollo/client";
import {useParams} from "react-router";
import {MEETING_START_MUTATION, UPDATE_MEETING_SETTINGS} from "../../../statements.graphql";
import Footer from "../../../Footer";
import Header from "../../../Header";
import EditableAgenda, {agendaTotalDuration} from "../../../components/EditableAgenda";
import {AgendaItem, Meeting, Mutation, MutationUpdateMeetingSettingsArgs} from "../../../graphql/generated/graphql";
import * as log from 'loglevel'
import EditableTitle from "../../../components/EditableTitle";
import EditableContainer from "../../../components/EditableContainer";
import CopyMeetingUrl from "../../../components/CopyMeetingUrl";


const WaitingPage = ({meeting}: { meeting: Partial<Meeting> }) => {

    const {meetingId} = useParams();
    const {setVisible, bindings} = useModal()
    const [agenda, setAgenda] = useState(meeting.agenda?.map(a => ({
        topic: a.topic,
        durationInMinutes: a.durationInMinutes
    })))
    const [editable, setEditable] = useState(false)

    const [startMeeting] = useMutation(MEETING_START_MUTATION, {
        variables: {
            publicId: meetingId
        }
    });

    const [updateMeetingMutation] = useMutation<{ updateMeetingSettings: Mutation['updateMeetingSettings'] }, MutationUpdateMeetingSettingsArgs>(UPDATE_MEETING_SETTINGS)

    const updateAgenda = (newAgenda: AgendaItem[]) => {
        const cache = agenda
        setAgenda(newAgenda)
        updateMeetingMutation({
            variables: {
                publicId: meetingId,
                meetingSettingsUpdate: {
                    agenda: newAgenda
                }
            }
        }).then(() => {
        })
            .catch(() => setAgenda(cache))
    }
    const updateTitle = (title: string) => {
        updateMeetingMutation({
            variables: {
                publicId: meetingId,
                meetingSettingsUpdate: {
                    title
                }
            }
        }).then(() => {
        })
    }

    const setTitle = (title: string) => {
        if (title !== meeting.title) {
            log.trace('Updating title', title)
            updateTitle(title)
        }
    }

    const ConfirmModal = () => {
        return (
            <Modal {...bindings}>
                <Modal.Title>Start meeting</Modal.Title>
                <Modal.Content>
                    <p>Are you sure you want to start the meeting?</p>
                </Modal.Content>
                <Modal.Action passive onClick={() => setVisible(false)}>Cancel</Modal.Action>
                <Modal.Action onClick={() => startMeeting().then(() => {
                })}>Yes!</Modal.Action>
            </Modal>
        )
    }

    useEffect(() => {
        setAgenda(meeting.agenda?.map(a => ({topic: a.topic, durationInMinutes: a.durationInMinutes})))
    }, [meeting.agenda])

    return (
        <>
            <Page size="small" style={{paddingTop: '0'}}>
                <Header headerTitle={"Waiting"}/>
                <Page.Content>

                    <ConfirmModal/>

                    <Row justify={"space-between"} align={"middle"}>
                        <Col span={20}>
                            <EditableContainer top={'30%'} alwaysActive={true}>
                                <EditableTitle defaultTitle={meeting.title!} setTitle={setTitle}/>
                            </EditableContainer>
                        </Col>

                        <CopyMeetingUrl toastText={'Link copied to the clipboard.'} meetingId={meetingId}/>
                    </Row>

                    <Row align={"middle"}>
                        <Spacer x={0.1}/>
                        <span>{new Date(meeting.start).toLocaleString().slice(0, -3)}</span>
                        <Spacer x={1}/>
                        <Badge size={"small"} type={"warning"}>Not started</Badge>
                    </Row>

                    <Spacer y={2}/>

                    <Spacer y={2}/>

                    <EditableContainer setEditable={setEditable} showDefault={true}>
                        <Row justify={"space-between"}>
                            <Text h4>Agenda</Text>
                            <div>
                                <Text small>Total duration:</Text> <Text small
                                                                         b>{agendaTotalDuration(agenda!)} min</Text>
                            </div>
                        </Row>

                        <EditableAgenda agenda={agenda!} setAgenda={updateAgenda} editable={editable}/>
                    </EditableContainer>

                    <Spacer y={2}/>
                    <Row justify={"space-around"}>

                        <Container align={"middle"}>
                            <Button type={'success'}
                                    onClick={() => setVisible(true)}
                            >
                                Start meeting
                            </Button>
                        </Container>
                    </Row>
            </Page.Content>

          </Page>
          <Footer/>
        </>

    );
};

export default WaitingPage;
