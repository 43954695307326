import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {CssBaseline, GeistProvider} from "@geist-ui/react";
import {WebSocketLink} from '@apollo/client/link/ws';

import {ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache, split} from "@apollo/client";
import {getMainDefinition} from "@apollo/client/utilities";
import {CookiesProvider} from "react-cookie";
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";
import {SubscriptionClient} from "subscriptions-transport-ws";
import * as log from "loglevel";

const prefix = require('loglevel-plugin-prefix');

require('dotenv').config()

const httpLink = new HttpLink({
    uri: String(process.env.REACT_APP_BACKEND_URL)
});


const wsLink = new WebSocketLink({
    uri: String(process.env.REACT_APP_SUBSCRIPTION_URL),
    options: {
        reconnect: true,
        lazy: true,
        timeout: 500,
        connectionParams: {
            jwt: localStorage.getItem('token')
        },
        connectionCallback: (error, result) => {
            if (error) console.log(error)
            if (result) console.log(result)
        },
    }
});

// @ts-ignore at the time of writing the field is private and untyped
const subscriptionClient = wsLink.subscriptionClient as SubscriptionClient;

let disconnectedCount = 0;

subscriptionClient.onConnecting(() => {
    log.warn("connecting");
});

subscriptionClient.onConnected(() => {
    log.warn("connected");
});

subscriptionClient.onReconnecting(() => {
    log.warn("reconnecting");
});

subscriptionClient.onReconnected(() => {
    log.warn("reconnected");
});

subscriptionClient.onDisconnected(() => {
    disconnectedCount++;
    if (disconnectedCount > 2) {
        disconnectedCount = 0;
        // window.location.reload();
    }
    log.warn("disconnected");
});

const splitLink = split(
    ({query}) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
);

const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
        graphQLErrors.map((item) => {
            if (item && item.extensions) {
                switch (item.extensions.code) {
                    case 'UNAUTHENTICATED':
                        // Clear token
                        try {
                            localStorage.removeItem('token')
                        } catch (e) {}
                        // Redirect to login page
                        let path = [...window.location.href.split('/')]
                        const pathObject = {
                            origin: path[0] +  "//" + path[2],
                            pathname: null
                        }
                        const base64redirect = Buffer.from(JSON.stringify(pathObject), 'utf-8').toString('base64')
                        window.location.replace(`https://www.takkt.io/api/blackmagic/${base64redirect}`)
                        break;
                    default:
                        log.error(
                            `[GraphQL error]: Message: ${item.message}, Location: ${JSON.stringify(item.locations)}, Path: ${item.path}`,
                        )
                }
            }
        })
    }
    if (networkError) log.error(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, {headers}) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            Authorization: token ? `${token}` : "",
        }
    }
});


const link = from([
    authLink,
    errorLink,
    splitLink
]);

const client = new ApolloClient({
    uri: process.env.BACKEND_URL,
    cache: new InMemoryCache({
        typePolicies: {
            Meeting: {
                keyFields: ['publicId'],
                fields: {
                    MeetingEvent: {
                        keyArgs: ['hash']
                    }
                }
            }
        }
    }),
    link: link
});


// Logging

const colors = {
    TRACE: '\x1b[36m', // cyan
    DEBUG: '\x1b[34m', // blue
    INFO: '\x1b[35m', // magenta
    WARN: '\x1b[31m', // red
    ERROR: '\x1b[91m\x1b[40m', // light-red; black-background
};

prefix.reg(log);
log.enableAll();

prefix.apply(log, {
    format(level: any, name: any, timestamp: any) {
        // @ts-ignore
        return `[${timestamp}] ${colors[level.toUpperCase()]}${level.toUpperCase()}: \x1b[49m \x1b[39m`//`${chalk.gray(`[${timestamp}]`)} ${colors[level.toUpperCase()](level)} ${chalk.green(`${name}:`)}`;
    },
});


if (process.env.NODE_ENV === 'production') {
    log.setLevel(log.levels.WARN)
} else {
    log.setLevel(log.levels.TRACE)
}

ReactDOM.render(
    <React.StrictMode>
        <GeistProvider>
            <CssBaseline/>
            <ApolloProvider client={client}>
                <CookiesProvider>
                    <App/>
                </CookiesProvider>
            </ApolloProvider>
        </GeistProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
