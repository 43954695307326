import React from 'react';
import {Button, Image, Row, Spacer, Text} from "@geist-ui/react";
import levitate from '../../resources/levitate.svg'
import {useHistory} from "react-router";


const EmptyPage = () => {
    const history = useHistory()

    const buttonClick = () => {
        history.push('/create')
    }

    return (
        <div>
            <>
                        <Spacer y={2}/>
                        <Image src={levitate} width={400}/>
                        <Row justify={"space-around"}><Text h2>You have no meetings at the moment!</Text></Row>
                        <Spacer y={1}/>
                        <Row justify={"space-around"}><Button type={"secondary"} onClick={buttonClick}>Create a
                            meeting</Button></Row>
            </>
        </div>
    );
};

export default EmptyPage;
