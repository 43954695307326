import React, {useState} from 'react';
import {Button, Card, Popover, Row, Tag, Text, Tooltip} from "@geist-ui/react";
import {Delete, Edit, MoreVertical} from '@geist-ui/react-icons'
import {MeetingEvent, MeetingEventType} from "../../../../../graphql/generated/graphql";
import Linkify from 'react-linkify';

export type PostProps = {
    post: MeetingEvent
    setEditingPost: React.Dispatch<React.SetStateAction<(MeetingEvent | null)>>
    deletePost: (hash: string) => void
}


const Post = ({post, setEditingPost, deletePost}: PostProps) => {

    const MoreContent = () => {
        return (
            <>
                <Popover.Item>
                    <Button icon={<Edit/>} size={"small"} onClick={() => {
                        setEditingPost(post)
                        setPopoverVisible(false)
                    }}>Edit</Button>
                </Popover.Item>
                <Popover.Item>
                    <Button icon={<Delete/>} size={"small"} type={'error'}
                            onClick={() => {
                                deletePost(post.hash!)
                            }}>Delete</Button>
                </Popover.Item>
            </>
        )
    }

    const [popoverVisible, setPopoverVisible] = useState(false)
    const changeHandler = (next: boolean) => {
        setPopoverVisible(next)
    }

    const linkComponent = (decoratedHref: string, decoratedText: string, key: number) => {
        return <a key={key} target={'_blank'} rel="noopener noreferrer" href={decoratedHref}>{decoratedText}</a>
    }


    return (
        <Card width={'100%'} type={post.type === MeetingEventType.Decision ? 'success' : 'default'}>

            <div style={{display: "flex", flexDirection: 'row', alignItems: "center"}}>
                <div style={{flex: '1 1 80%', overflow: 'auto'}}>
                    <Text small>{post.authorAvatar.avatarObject} </Text> <Text small
                                                                               style={{marginLeft: '5px'}}>{post.author.nickname}</Text>
                    <br/>
                    <Text b style={{whiteSpace: "pre-wrap", wordWrap: "break-word"}}>
                        <Linkify componentDecorator={linkComponent}>{post.textBody}</Linkify>
                    </Text>
                    {post.type === MeetingEventType.Task &&
                    <Row style={{marginTop: '10px'}}>
                        {post.dueDate && <div>
                            <Tooltip text={'Due date'} placement={"bottom"}>
                                <Tag type={"secondary"}>{new Date(post.dueDate).toLocaleDateString()}</Tag>
                            </Tooltip></div>}
                        {post.responsible && <div style={{marginLeft: '20px'}}>
                            <Tooltip text={'Assigne'} placement={"bottom"}>
                                <Tag type={"warning"}>{post.responsible.nickname}</Tag>
                            </Tooltip></div>}
                    </Row>}
                </div>
                <div style={{flex: '0 1 20px'}}>
                    <Popover content={MoreContent} visible={popoverVisible} onVisibleChange={changeHandler}>
                        <MoreVertical/>
                    </Popover>
                </div>
            </div>
        </Card>
    );
};

export default Post;
