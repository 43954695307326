import React from 'react';
import {Route, RouteProps, useLocation} from "react-router";

export interface ProtectedRouteProps extends RouteProps {
    noToken?: boolean
    default?: boolean
}

const ProtectedRoute = (props: ProtectedRouteProps) => {

    const {pathname} = useLocation()

    if (props.noToken || localStorage.getItem('token')) {
        return (
            <Route {...props}>
                {props.children}
            </Route>
        );
    } else {
        {
            let path = [...window.location.href.split('/')]
            const pathObject = {
                origin: path[0] +  "//" + path[2],
                targetUrl: pathname
            }
            const base64redirect = Buffer.from(JSON.stringify(pathObject), 'utf-8').toString('base64')
            window.location.replace(`https://www.takkt.io/api/blackmagic/${base64redirect}`)
        }
        return null
    }
};

export default ProtectedRoute;
