import React, {useMemo} from 'react';
import {MeetingEvent, Series as SeriesType, SeriesInterval} from "../../graphql/generated/graphql";
import Header from "../../Header";
import {Badge, Button, Grid, Input, Page, Popover, Row, Spacer, Text, useInput, useModal} from "@geist-ui/react";
import MeetingItem from "../4-Dashboard/MeetingItem";
import TaskElement from "../3-Summary/Tasks/TaskElement";
import {Settings, UserPlus} from "@geist-ui/react-icons";
import LeaveSeriesModal from "./LeaveSeriesModal";
import moment from "moment";
import {useHistory} from "react-router";


type SeriesProps = {
    series: SeriesType
    addParticipant: (email: string) => void
    leaveSeries: () => void
}

const Series = ({series, addParticipant, leaveSeries}: SeriesProps) => {

    const history = useHistory()
    const {bindings, state: email, reset: resetEmail} = useInput('')
    const modal = useModal()

    const decisions = useMemo(() => {
        const lastMeeting = series.meetings.filter(m => !!m.summary).slice(-1)[0]
        if (lastMeeting && lastMeeting.summary) {
            return (lastMeeting.summary.decisions as any).objects as MeetingEvent[]
        } else {
            return []
        }
    }, [series])

    const tasksPerMeeting = useMemo(() => {
        return series.meetings.filter(m => m.summary).flatMap((m, _index) => {
            return {
                meetingId: m.publicId,
                tasks: m.summary!.tasks?.objects?.filter(o => o && !o.done) as MeetingEvent[]
            }
        })
    }, [series])

    const participantInputHandler = (ev: React.KeyboardEvent) => {
        if (ev.key === "Enter") {
            addParticipant(email)
            resetEmail()
        }
    }

    const seriesIntervalLabel = (startDate: Date, interval: SeriesInterval) => {
        const weekdayLabel = moment.weekdays()[moment(startDate).weekday()]
        switch (interval) {
            case SeriesInterval.Daily:
                return 'Every day at ' + startDate.toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'})
            case SeriesInterval.Weekly:
                return `Every week on ${weekdayLabel} at ${startDate.toLocaleTimeString(undefined, {
                    hour: '2-digit',
                    minute: '2-digit'
                })}`
            case SeriesInterval.Monthly:
                const th = moment(startDate).week() - moment(startDate).startOf('month').week()
                return `Monthly every ${['first', 'second', 'third', 'fourth'][th]} ${weekdayLabel} at ${startDate.toLocaleTimeString(undefined, {
                    hour: '2-digit',
                    minute: '2-digit'
                })}`
            case SeriesInterval.MonthlyLast:
                return `Every month on the last ${weekdayLabel} at ${startDate.toLocaleTimeString(undefined, {
                    hour: '2-digit',
                    minute: '2-digit'
                })}`
            default:
                return 'Something went wrong'
        }
    }

    return (
        <Page>
            <Header headerTitle={'Series'}/>
            <Page.Content>

                <LeaveSeriesModal modal={modal} leaveSeries={leaveSeries}/>

                <Row justify={"space-between"}>
                    <Text h3>{series.title}</Text>

                    <div style={{display: 'flex', flexDirection: "row", alignItems: 'center'}}>
                        <div style={{marginLeft: 'auto'}}/>
                        {series.meetings[0].previous &&
                        <div><Badge style={{marginRight: '2em', cursor: 'pointer'}} size={"small"}
                                    onClick={() => history.push('/meeting/' + series.meetings[0].previous!.publicId)}>Follow-Up</Badge>
                        </div>}
                        <Popover content={<Popover.Item><Button ghost type={"error"} auto size={"small"}
                                                                onClick={() => modal.setVisible(true)}>Leave
                            series</Button></Popover.Item>}><Button type={"abort"} auto icon={<Settings/>}/></Popover>
                    </div>
                </Row>
                <Row align={"middle"}>
                    {seriesIntervalLabel(new Date(series.start), series.interval!)}
                    <Spacer x={0.2}/>
                </Row>
                <Spacer y={2}/>
                <Grid.Container direction={"row-reverse"} gap={5}>

                    <Grid xs={24} md={8}>{/* Participants */}
                        <Text h3>Participants</Text>
                        <ul>
                            {
                                series.participants.map(p => <li key={p.userId}>{p.nickname} ({p.email})</li>)
                            }
                        </ul>
                        <Spacer y={1}/>
                        <Row>
                            <Input width={'100%'}
                                   {...bindings}
                                   clearable icon={<UserPlus/>}
                                   placeholder="Invite new participants"
                                   onKeyDown={participantInputHandler}
                            />
                        </Row>

                    </Grid>

                    <Grid xs={24} md={16}>{/* Meetings & Facts */}

                        <Grid.Container>
                            <Grid xs={24} sm={12}>
                                {
                                    decisions && decisions.length > 0 ?
                                        <><Text h4>Last Decisions</Text>
                                            <ul>
                                                {
                                                    decisions.map(m => {
                                                        return <li>{m.textBody}</li>
                                                    })
                                                }
                                            </ul>
                                        </>
                                        : <Text h4>No Last Decisions</Text>
                                }
                                <Spacer y={1}/>
                            </Grid>

                            <Grid xs={24} sm={12}>
                                {
                                    tasksPerMeeting && tasksPerMeeting.flatMap(tm => tm.tasks).length > 0 ?
                                        <><Text h4>Tasks</Text>
                                            <ul>
                                                {
                                                    tasksPerMeeting.map(tm => {
                                                        return tm.tasks.map(t => <TaskElement key={t.hash} event={t}
                                                                                              meetingId={tm.meetingId}/>)
                                                    })
                                                }
                                            </ul>
                                        </>
                                        : <Text h4>No open Tasks</Text>
                                }
                            </Grid>

                            <Grid xs={24}>
                                <Spacer y={2}/>
                            </Grid>

                            <Grid xs={24}>
                                <Text h3>Meetings</Text>
                                {
                                    Array.from(series.meetings!).reverse().map(m => <MeetingItem key={m.publicId}
                                                                                                 meeting={m}
                                                                                                 partOfSeries={true}/>)
                                }
                            </Grid>

                        </Grid.Container>
                    </Grid>

                </Grid.Container>
            </Page.Content>
        </Page>
    );
};

export default Series;
