import React, {useMemo} from 'react';
import {MeetingEvent, User} from "../../../graphql/generated/graphql";
import {Card, Checkbox, Row, Text} from '@geist-ui/react'
import TaskElement from "./TaskElement";
import {useParams} from "react-router";

type GroupedBy = {
    [userId: string]: { tasks: MeetingEvent[], label: string },
}

const Tasks = ({tasks}: { tasks: MeetingEvent[] }) => {

    const {meetingId} = useParams()

    const groupedByResponsible = useMemo(() => {
        return tasks.reduce<GroupedBy>(
            (acc, current) => {
                if (current.responsible) {
                    if (current.responsible.userId in acc) {
                        acc[current.responsible.userId].tasks.push(current)
                    } else acc[current.responsible.userId] = {label: current.responsible.nickname, tasks: [current]}
                } else {
                    if ('notAssigned' in acc) {
                        acc.notAssigned.tasks.push(current)
                    } else {
                        acc['notAssigned'] = {label: "No responsible set", tasks: []}
                    }
                }
                return acc
            }
            , {})

    }, [tasks])

    return (<div>
            {Object.keys(groupedByResponsible).map((userId: string) => {
                if (groupedByResponsible[userId].tasks.length === 0) return null;
                return <Card style={{marginTop: '1em'}} key={userId}>
                    <Text b>{groupedByResponsible[userId].label}</Text>
                    {groupedByResponsible[userId].tasks.map(t => <TaskElement key={t.hash} event={t}
                                                                              meetingId={meetingId}/>)}
                </Card>
            })}
        </div>
    );
};

export default Tasks;
