import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {
    Row,
    Card,
    Progress,
    Text,
    useTheme,
    Button,
    Divider,
    Spacer,
    Container, Col, Tooltip, Badge, Link, Avatar, useModal, Modal
} from "@geist-ui/react";
import {
    AgendaItem,
    MutationCloseMeetingArgs,
    Participant
} from "../../../../graphql/generated/graphql";
import {Duration} from 'luxon'
import OnlineAvatars from "./OnlineAvatars";
import {CornerDownRight} from "@geist-ui/react-icons";

import "./MeetingHeader.css";
import {useMutation} from "@apollo/client";
import {MEETING_CLOSE} from "../../../../statements.graphql";
import {useParams} from "react-router";

export type MeetingHeaderProps = {
    agendaItems: AgendaItem[],
    startTime: number,
    topics: {
        topicIndex: number,
        forward: () => void,
        backward: () => void
    },
    participants: Participant[],
    topicHistory: any
}

const MeetingHeader = ({agendaItems, startTime, topics, participants, topicHistory}: MeetingHeaderProps) => {

    const maxTime = agendaItems.map((item) => item.durationInMinutes).reduce((a, b) => a + b, 0)

    const currentTopic = useMemo(() => {
        return agendaItems[topics.topicIndex].topic || ''
    }, [agendaItems, topics.topicIndex])


    const nextTopic = useMemo(() => {
        if (topics.topicIndex < agendaItems.length - 1) {
            return agendaItems[topics.topicIndex + 1] || ''
        } else {
            return null
        }
    }, [agendaItems, topics.topicIndex])


    const getTimeSpentOnOtherTopics = () => {
        return (topicHistory.filter((item: any) => {
            return item.topic !== topics.topicIndex;
        })
            .map((item: any) => item.duration)
            .reduce((acc: any, next: any) => acc + next, 0) / 1000)
    }

    const tick = () => {
        const now = Date.now();
        const progress = (now - new Date(startTime).getTime()) / 1000;
        setProgress(progress);
        setRemainingTime();
    }

    const setRemainingTime = () => {
        const remainingTime = (agendaItems[topics.topicIndex].durationInMinutes * 60 - (progress - timeSpentForOtherTopics))
        setSign(Math.sign(remainingTime))
        setCurrentTopicRemainingTime(Math.abs(remainingTime));
    }


    const totalDurationInSeconds = useMemo(() => {
        return agendaItems.map(a => a.durationInMinutes).reduce((prev, curr) => prev + curr, 0) * 60
    }, [agendaItems])

    const [progress, setProgress] = useState((Date.now() - new Date(startTime).getTime()) / 1000) // time since started in seconds
    const [currentTopicRemainingTime, setCurrentTopicRemainingTime] = useState(0)
    const [sign, setSign] = useState(0)
    const [timeSpentForOtherTopics, setTimeSpentForOtherTopics] = useState(getTimeSpentOnOtherTopics())
    const [showCloseConfirmation, setShowCloseConfirmation] = useState(false)


    useEffect(() => {
        setTimeSpentForOtherTopics(getTimeSpentOnOtherTopics)
    }, [topicHistory])

    useEffect(() => {
        tick()
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            tick()
        }, 100)
        return () => clearInterval(interval)
    })


    const {meetingId} = useParams();
    const [closeMeeting] = useMutation<any, MutationCloseMeetingArgs>(MEETING_CLOSE);

    const close = () => {
        closeMeeting({
            variables: {
                publicId: meetingId
            }
        }).then(() => {

        })
    };

    const { visible, setVisible, bindings } = useModal()

    const currentTheme = useTheme()


    return (
        <Fragment>
            <Row justify={"space-between"} align={'middle'}>
                <Col span={21.5}>
                    <Progress value={progress} max={totalDurationInSeconds}
                              type={progress > totalDurationInSeconds ? 'error' : 'secondary'}/>
                </Col>
                <Col span={2}>
                    <div style={{padding: '0 0 4px 0', margin: '0', textAlign: 'right'}}>
                            <span style={{fontSize: '10px'}}>
                            {Duration.fromObject({seconds: progress}).toFormat("m:ss")}
                                <span style={{
                                    display: "inline",
                                    color: currentTheme.palette.secondary
                                }}> / {Duration.fromObject({seconds: totalDurationInSeconds}).toFormat("m:ss")}
                        </span>
                        </span>
                    </div>
                </Col>
            </Row>
            <Row justify={"space-between"} align={'middle'} style={{background: 'white'}}>
                <Container align={'middle'}>

                    <Row align={"middle"}>
                        { (topics.topicIndex > 0) &&
                        <>
                            <div style={{cursor: "pointer"}}
                                 onClick={() => {
                                     if (true) {
                                         topics.backward();
                                     }
                                 }}>
                                <Avatar text="◀️"/>
                            </div>
                            <Spacer x={1}/>
                        </>
                        }



                        <div style={{minWidth: "300px", border: "1px solid #efefef", padding: "3px 10px", borderRadius: "15px"}}>
                            <Row justify={"space-between"}>
                                <div>
                                    {currentTopic}
                                </div>
                                <div>
                                <span style={{color: (sign < 0 ? 'red' : 'grey')}}>
                                    {sign < 0 ? '-' : ''}{Duration.fromObject({seconds: currentTopicRemainingTime}).toFormat("m:ss")}
                                </span>
                                </div>
                            </Row>
                        </div>

                        <Spacer x={1}/>

                        { (topics.topicIndex < agendaItems.length - 1) &&
                        <Tooltip text={`Up next: ${nextTopic ? nextTopic.topic : ''}`}>
                            <div style={{cursor: "pointer"}}
                                 onClick={() => {
                                     if (topics.topicIndex < agendaItems.length - 1) {
                                         topics.forward();
                                     }
                                 }}>
                                <Avatar text="▶️"/>
                            </div>
                        </Tooltip>
                        }
                    </Row>

                    {!(topics.topicIndex < agendaItems.length - 1) &&
                    <>
                        <Spacer x={5}/>
                        <div>
                            <Button size={"mini"} onClick={() => setVisible(true)} type={"error"} ghost style={{cursor: "pointer"}}>
                                Close meeting
                            </Button>
                        </div>
                    </>
                    }

                </Container>
                <Container>
                    <OnlineAvatars participants={participants}/>
                </Container>
            </Row>
            <Row justify={"space-between"}>
                <div>

                </div>

            </Row>

            <>
                <Modal {...bindings}>
                    <Modal.Title>🚨 Close meeting? 🚨</Modal.Title>
                    <Modal.Content>
                        <p style={{textAlign: "center"}}>Closing the meeting will end it for everyone and redirect you to the <b>summary page</b>.</p>
                    </Modal.Content>
                    <Modal.Action passive onClick={() => setVisible(false)}>Cancel</Modal.Action>
                    <Modal.Action onClick={() => close()} style={{color: "red", fontWeight: "bold"}}>Close</Modal.Action>
                </Modal>
            </>
        </Fragment>
    );
};

export default MeetingHeader;
