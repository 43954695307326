import React from 'react';
import {Loading, Page, useToasts} from "@geist-ui/react";
import Header from "../../Header";
import Series from "./Series";
import {useHistory, useParams} from "react-router";
import {useMutation, useQuery} from "@apollo/client";
import {SERIES_QUERY, SERIES_UPDATE} from "../../statements.graphql";
import {Mutation, Query, Series as SeriesType, SeriesUpdate} from "../../graphql/generated/graphql";
import jwt from 'jsonwebtoken'

const testSeries: any = {
    title: "Test series",
    participants: [
        {
            nickname: 'Alfred',
            email: 'alfred@takkt.io'
        },
        {
            nickname: 'Benjamin',
            email: 'benjamin@takkt.io'
        },
    ],
    meetings: [
        {
            title: "Test meeting #1"
        },
        {
            title: "Test meeting #2"
        },
    ]
}

const SeriesWithData = () => {

    const {seriesId} = useParams()
    const history = useHistory()
    const [, setToasts] = useToasts()

    const {loading, error, data, refetch} = useQuery<{ series: Query['series'] }>(SERIES_QUERY, {
        variables: {
            seriesId
        }
    })


    const [updateSeries, {}] = useMutation<{ updateSeries: Mutation["updateSeries"] }>(SERIES_UPDATE)

    const addParticipant = (email: string) => {
        updateSeries({
            variables: {
                publicId: seriesId,
                update: {
                    addParticipants: [email]
                } as SeriesUpdate
            }
        }).then(() => {
            setToasts({
                text: 'Successfully added a new participant',
                type: 'success'
            })
            refetch && refetch().then(() => {
            })
        })
    }

    const leaveSeries = () => {
        const {email} = jwt.decode(localStorage.getItem('token')!) as any;
        email && updateSeries({
            variables: {
                publicId: seriesId,
                update: {
                    removeParticipants: [email]
                }
            }
        }).then(() => {
            setToasts({
                text: 'You left the series.'
            })
            history.replace('/dashboard')
        })
    }

    if (loading) return <Loading/>

    if (!data) return null

    return (
        <Series series={data.series as SeriesType} addParticipant={addParticipant} leaveSeries={leaveSeries}/>
    );
};

export default SeriesWithData;
