import React, {useState} from 'react';
import {Steps} from "antd";
import {StepWizardChildProps} from "react-step-wizard";
import {StepperLabels} from "./index";
import {Spacer} from "@geist-ui/react";

const {Step} = Steps

export interface StepperNavProps extends Partial<StepWizardChildProps> {

}

const StepperNav = (props: StepperNavProps) => {

    const onChange = (current: number) => {
        props.goToStep!(current + 1)
    }

    return (
        <>
            <Steps size={"small"} current={props.currentStep! - 1} onChange={onChange}>
                {
                    StepperLabels.map((label, index) => {
                        return <Step key={index} title={label} disabled={props.currentStep! - 1 < index}/>
                    })
                }
            </Steps>
            <Spacer y={2}/>
        </>
    );
};

export default StepperNav;
