import React from 'react';
import {Popover} from '@geist-ui/react';
import {useHistory} from "react-router";
import jwt from "jsonwebtoken";


const UserMenu = () => {

    const history = useHistory()

    const logout = () => {
        localStorage.removeItem('token')
        history.push({pathname: '/', state: {notification: {type: 'success', text: 'logged out'}}})
    }

    const {email} = jwt.decode(localStorage.getItem('token')!) as any;

    return (
        <>
            <Popover.Item>
                {email}
            </Popover.Item>
            <Popover.Item line/>
            <Popover.Item>
                <a onClick={logout}>Logout</a>
            </Popover.Item>
        </>
    );
};

export default UserMenu;
