import React from 'react';
import {Container, Row, Spacer, Text} from "@geist-ui/react";
import MeetingItem from "./MeetingItem";
import {User} from "../../graphql/generated/graphql";
import EditableContainer from "../../components/EditableContainer";
import MeetingItemMenu from "../../components/MeetingItemMenu";

const DashboardMeetings = ({user, refetch}: { user: Partial<User>, refetch: any }) => {
    return (
        <div>
            <div style={{height: '60px'}}>
                <Row justify={"space-between"}>
                    <Container align={"middle"}>
                        <Text h2>Meetings</Text>
                        <Spacer x={0.2}/>
                        <Text small>({user.meetings?.length})</Text>
                    </Container>
                </Row>
            </div>

            <div style={{margin: '20px, 0'}}>
                {Array.from(user.meetings!).reverse().map(m => {
                    return (
                        <EditableContainer key={m.publicId} customButton={MeetingItemMenu} left={"-3.3em"} top={"10px"}
                                           customProps={{meetingId: m.publicId, refetch}}>
                            <MeetingItem meeting={m} refetch={refetch}/>
                        </EditableContainer>
                    )
                })}
            </div>
        </div>
    );
};

export default DashboardMeetings;
