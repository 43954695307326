import React from 'react';
import {Loading, Modal, Page, Text} from "@geist-ui/react";
import Header from "../../Header";
import {useParams} from "react-router";
import {Helmet} from "react-helmet";
import {useQuery} from "@apollo/client";
import {FOLLOWUP_QUERY} from "../../statements.graphql";
import {Meeting, QueryMeetingArgs} from "../../graphql/generated/graphql";
import {DragDropContext} from "react-beautiful-dnd";
import EditableAgenda from "../../components/EditableAgenda";

const Followup = () => {

    const {publicId} = useParams()

    const {data, loading, error} = useQuery<{ meeting: Meeting }, QueryMeetingArgs>(FOLLOWUP_QUERY, {
        variables: {
            publicId
        }
    })

    if (loading) return <Loading/>

    if (!data) return <Loading/>

    const {meeting} = data

    return (
        <Page>
            <Header headerTitle={"Follow up"}/>

            <Helmet>
                <title>Follow up</title>
            </Helmet>

            <Page.Content>
                <Text h3>Create a follow up meeting for</Text>
                <Text h1>{meeting.title}</Text>

                <EditableAgenda agenda={data.meeting.agenda} setAgenda={() => {
                }} editable={false}/>
            </Page.Content>
        </Page>
    );
};

export default Followup;
