import React from 'react';
import {Participant} from "../graphql/generated/graphql";
import {Tooltip} from "@geist-ui/react";

export type ParticipantsProps = {
    participants: Participant[] | undefined
    showName?: boolean
}

const Participants = ({participants, showName}: ParticipantsProps) => {

    if (showName) {
        return <>
            {
                participants ? participants.map(p => {
                    return <span
                        style={{marginRight: '0.4em'}}>{p.avatar.avatarObject} {p.user.nickname}</span>
                }) : null
            }
        </>
    }

    return <>
        {
            participants ? participants.map(p => {
                return <Tooltip key={p.user.userId} text={p.user.nickname}><span
                    style={{marginRight: '0.4em'}}>{p.avatar.avatarObject}</span> </Tooltip>
            }) : null
        }
    </>
};

export default Participants;
