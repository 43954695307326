import React, {useEffect, useState} from 'react';
import {User} from "../../graphql/generated/graphql";
import {Loading, Page, Spacer, Text, useToasts} from '@geist-ui/react'
import {ApolloError} from "@apollo/client";
import Header from "../../Header";
import {useHistory, useLocation} from "react-router-dom";
import Footer from "../../Footer";
import DashboardMeetings from "./DashboardMeetings";
import DashboardSeries from "./DashboardSeries";
import EmptyPage from "./EmptyPage";

export type DashboardProps = {
    loading: boolean
    error: ApolloError | undefined
    user: Partial<User> | undefined
    refetch: () => void
}
const Dashboard = (props: DashboardProps) => {

    const {user} = props
    const {state} = useLocation()
    const history = useHistory()
    const [, setToast] = useToasts()

    const [message,] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (state && state.notification) {
            setToast({
                type: state.notification.type,
                text: state.notification.text
            })
            history.replace({state: {}})
        }
    }, [state])

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            history.push({
                pathname: '/',
                state: {
                    notification: {type: 'warning', text: 'Not logged in'}
                }
            })
        }
    })

    if (props.loading) return <Loading/>

    if (message !== undefined) return <Text h2 type={'success'}>{message}</Text>

    return (
        <>
            <Page>
                <Header headerTitle={'Dashboard'}/>
                <Page.Content style={{paddingTop: '0', height: 'calc(100vh - 60px)'}}>
                    {user &&
                    <>
                        {
                            user && user.series && user.series.length == 0 ?
                                null
                                :
                                <DashboardSeries series={user!.series as any}/>
                        }
                        <Spacer y={2}/>
                        {
                            user && user.meetings && user.meetings.length == 0 ?
                                <EmptyPage/>
                                :
                                <DashboardMeetings user={user!} refetch={props.refetch}/>
                        }
                        <Spacer y={2}/>
                    </>
                    }
                </Page.Content>
            </Page>
            <Footer/>
        </>
    );
};

export default Dashboard;
