import {Loading, Page, Text} from "@geist-ui/react";
import React from "react";
import MeetingHeader from './MeetingHeader';
import MeetingInput from "./MeetingInput";
import {ApolloError} from "@apollo/client";
import {
    Meeting as MeetingType,
    MeetingEvent,
    MeetingEventInput,
    MetricInput,
    Participant
} from "../../../graphql/generated/graphql";
import MeetingStage from "./MeetingStage/MeetingStage";
import Header from "../../../Header";
import "../meeting.css"
import MeetingMetrics from "./MeetingMetrics";

export type MeetingRenderProps = {
    meeting: MeetingType | undefined,
    loading: boolean,
    error: ApolloError | undefined,
    addEvent: (event: Pick<MeetingEventInput, 'type' | 'textBody'>) => void
    topics: {
        topicIndex: number,
        forward: () => void,
        backward: () => void
    },
    editing: [(MeetingEvent | null), React.Dispatch<React.SetStateAction<(MeetingEvent | null)>>],
    updatePost: (post: MeetingEventInput) => void
    deletePost: (hash: string) => void
    topicHistory: any
    setTypingStatus: (typingStatus: boolean) => void
    addMetric: (metric: MetricInput) => Promise<unknown>
}

const MeetingRender = (props: MeetingRenderProps) => {
    const {meeting, loading, error, addEvent, topics, editing, updatePost, deletePost} = props
    if (loading) return <Loading/>
    if (error || meeting === undefined) return <Text type={'error'}>{error?.message} || meeting undefined</Text>

    return (
        <Page>
            <Header headerTitle={"Meeting"}/>
            <Page.Content style={{padding: 0, height: 'calc(100vh - 60px)'}}>
                <div style={{display: "flex", flexDirection: 'column', height: '100%'}}>
                    <div style={{height: '100px'}}>
                        <MeetingHeader agendaItems={meeting.agenda} startTime={meeting.started} topics={topics}
                                       participants={props.meeting!.participants as Participant[]}
                                       topicHistory={props.topicHistory}/>
                    </div>
                    <div style={{height: '140px'}}>
                        <MeetingMetrics metrics={meeting.metrics} prevMetrics={meeting.previous?.metrics!}
                                        addMetric={props.addMetric}/>
                    </div>
                    <div style={{height: 'calc(100% - 490px)', marginTop: '30px'}}>
                        <MeetingStage events={meeting.meetingEvents as MeetingEvent[]} editing={editing}
                                      currentTopic={topics.topicIndex}
                                      deletePost={deletePost}/>
                    </div>
                    <div style={{height: '200px', marginTop: '30px'}}>
                        <MeetingInput addEvent={addEvent} editing={editing} updatePost={updatePost}
                                      participants={meeting.participants!} setIsTyping={props.setTypingStatus}/>
                    </div>
                </div>
            </Page.Content>
        </Page>
    )
}

export default MeetingRender


