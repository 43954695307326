import React from 'react';

export type FlexboxProps = {
    flexDirection?: "inherit" | "-moz-initial" | "initial" | "revert" | "unset" | "row" | "column" | "column-reverse" | "row-reverse"
    justifyContent?: "space-between" | string | undefined
    alignItems?: 'center' | string
    height?: number | string
    maxWidth?: number | string
    children?: React.ReactNode
    width?: number | string
    flexGrow?: number
}

const Flexbox = (props: FlexboxProps) => {
    return (
        <div style={{display: 'flex', ...props}}>
            {props.children}
        </div>
    );
};

export default Flexbox;
