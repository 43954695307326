import React, {useState} from 'react';
import {AgendaItem} from "../graphql/generated/graphql";
import {DragDropContext, Droppable, DropResult} from "react-beautiful-dnd";
import AgendaItemsRendered from "../Routes/1-Create/2-Agenda/AgendaItemsRendered";
import AgendaItemModal from "../Routes/1-Create/2-Agenda/AgendaItemModal";
import {Row, useModal} from "@geist-ui/react";
import update from "immutability-helper";
import {reorder} from "../Routes/1-Create/2-Agenda/helper";
import {PlusCircle} from "@geist-ui/react-icons";

export type EditableAgendaProps = {
    agenda: AgendaItem[]
    setAgenda: (agenda: AgendaItem[]) => void
    editable: boolean | undefined
}

export const agendaTotalDuration = (agenda: AgendaItem[]): number => {
    return agenda.reduce<number>((prev, curr) => prev + curr.durationInMinutes, 0)
}

const EditableAgenda = (props: EditableAgendaProps) => {

    const {setAgenda, agenda} = props

    const {setVisible: setModalVisible, bindings} = useModal()
    const [editing, setEditing] = useState<{ index: number, item: AgendaItem } | undefined>(undefined)

    /* Local state helper functions */
    const updateAgendaItem = (index: number, item: AgendaItem) => {
        setAgenda(update(agenda, {[index]: {$set: item}}))
    }
    const createItem = (item: AgendaItem) => setAgenda([...agenda, item])
    const deleteItem = (index: number) => {
        let tmp = [...agenda]
        tmp.splice(index, 1)
        setAgenda(tmp)
    }
    const onDragEnd = ({source, destination}: DropResult) => {
        if (destination) {
            const items = reorder(
                agenda,
                source.index,
                destination!.index
            );
            setAgenda(items);
        }
    }

    if (!props.agenda) return null

    return (
        <div>

            <AgendaItemModal bindings={bindings} setVisible={setModalVisible} createItem={createItem}
                             editing={editing} updateItem={updateAgendaItem}
            />

            <DragDropContext onDragEnd={onDragEnd}>

                <Droppable droppableId={'editable-agenda'}>

                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            <AgendaItemsRendered items={props.agenda}
                                                 setEditing={((index, item) => setEditing({index, item}))}
                                                 setModalVisible={setModalVisible} deleteItem={deleteItem}
                                                 isEditable={props.editable}/>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {
                props.editable && <Row justify={"space-around"}>
                    <div style={{cursor: 'pointer'}} onClick={() => {
                        setEditing(undefined)
                        setModalVisible(true)
                    }}><PlusCircle color={'grey'}/></div>
                </Row>
            }

        </div>
    );
};

export default EditableAgenda;
