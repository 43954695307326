import React from 'react';
import {Card, Grid} from "@geist-ui/react";
import {MeetingConfigOptionItem} from "../../graphql/generated/graphql";
import {MeetingConfigType} from "./index";

type Props = {
    identifier: string
    options: MeetingConfigOptionItem[]
    selection: MeetingConfigType
    selectOption: (identifier: string, option: MeetingConfigOptionItem) => void
}

const RenderOptions = ({identifier, options, selection, selectOption}: Props) => {
    return (
        <Grid.Container gap={2} justify="center">
            {options.map((option) => (
                <Grid key={option.val} xs={12} sm={8} md={6}>
                    <Card style={{cursor: 'pointer'}}
                          hoverable
                          type={selection[identifier]?.value === option.val ? 'success' : 'default'}
                          onClick={() => {
                              selectOption(identifier, option)
                          }}
                    >
                        <div style={{display: "flex", flexDirection: 'column', alignItems: "center"}}>
                            <div style={{fontSize: '1.5em'}}>{option.graphic}</div>
                            <div>{option.label}</div>
                        </div>
                    </Card>
                </Grid>
            ))}
        </Grid.Container>
    );
};

export default RenderOptions;
