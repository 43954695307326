import React, {useState} from 'react';
import {StepWizardChildProps} from "react-step-wizard";
import {Button, Col, Row, Spacer, Text} from "@geist-ui/react";
import moment from "moment";
import {DatePicker, Form, Switch} from "antd";

import './geist-workaround.css'
import SelectSeriesInterval from "./SelectSeriesInterval";
import SelectParticipants from "../../components/SelectParticipants";
import {SeriesInterval} from "../../graphql/generated/graphql";
import {useForm} from "antd/es/form/Form";
import log from 'loglevel';
import {RuleObject} from "antd/es/form";

export interface MeetingOptionsProps extends Partial<StepWizardChildProps> {
    title: string
    setParticipants: (emails: string[]) => void
    setInterval: (interval: SeriesInterval) => void
    setMeetingStartDate: (date: Date) => void
    isSeries: boolean,
    setIsSeries: (status: boolean) => void,
    onSubmit: () => void
}

export const dateValidator = (rule: RuleObject, value: moment.Moment) => {
    if (value) {
        if (value < moment()) {
            return Promise.reject("Please select a time in the future")
        } else {
            return Promise.resolve()
        }
    } else {
        return Promise.reject('Please select a date')
    }
}

export function disablePastDates(current: moment.Moment) {
    // Can not select days before today and today
    return current && current < moment().subtract(1, "day").endOf('day');
}

const MeetingOptions = (props: MeetingOptionsProps) => {


    const [meetingStartDate, setMeetingStartDate] = useState<moment.Moment>(moment())
    const [form] = useForm()

    const {title, isSeries, setIsSeries} = props

    const selectDateTime = (date: moment.Moment | null, _dateString: string) => {
        setMeetingStartDate(date!)
        props.setMeetingStartDate(date!.toDate())
    }

    const toggleSeries = (status: boolean) => {
        setIsSeries(status)
    }

    const submit = () => {
        form.validateFields()
            .then(() => props.onSubmit())
            .catch(res => log.error(res))
    }

    const suggestions: string[] = []

    return (
        <div>
            <Text h2>{title}</Text>

            <Spacer y={2}/>

            <Row justify={"space-around"}>
                <Col span={12}>
                    <Form form={form}>
                        <Form.Item name={'meeting-start-date'} label={"Start date"}
                                   rules={[{type: 'object', required: true, validator: dateValidator}]}>
                            <DatePicker
                                disabledDate={disablePastDates}
                                style={{width: '100%'}}
                                placeholder={'Select start date'}
                                showTime={{
                                    defaultValue: moment('10:00', 'HH:mm'),
                                    minuteStep: 5,
                                    format: 'HH:mm'
                                }}
                                format='YYYY/MM/DD HH:mm'
                                onChange={selectDateTime}
                            />
                        </Form.Item>
                        <Form.Item label={'Recurrent meeting'}>
                            <Row align={"middle"}>
                                <Switch onChange={toggleSeries}/>
                                {
                                    isSeries &&
                                    <><Spacer x={2}/><SelectSeriesInterval meetingStartDate={meetingStartDate}
                                                                           setInterval={props.setInterval}/></>
                                }
                            </Row>
                        </Form.Item>

                        <Form.Item label={"Participants"}>
                            <SelectParticipants participantSuggestions={suggestions}
                                                setParticipants={props.setParticipants}/>
                        </Form.Item>
                    </Form>
                </Col>

            </Row>


            <Spacer y={2}/>

            <Row justify={"space-around"}>
                <Button type={"success"} onClick={submit}>Create meeting</Button>
            </Row>

        </div>
    );
};

export default MeetingOptions;
