import React, {useEffect} from 'react';
import Dashboard from "./Dashboard";
import {useQuery} from "@apollo/client";
import {DASHBOARD_QUERY} from "../../statements.graphql";
import {User} from "../../graphql/generated/graphql";

const DashboardWithData = () => {

    const {loading, error, data, refetch} = useQuery<{ user: User }>(DASHBOARD_QUERY)

    useEffect(() => {
        refetch()
    }, [])

    return (
        <Dashboard loading={loading} error={error} user={data?.user} refetch={refetch}/>
    );
};

export default DashboardWithData;
