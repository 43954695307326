// import {Provider} from "react-redux";
// import SummaryPage from "./Summary";
// import React from "react";
// import {applyMiddleware, createStore} from "redux";
// import {reducer} from "../2-Meeting/reducer";
//
//
// const Summary = () => {
//
//   const store = createStore(
//     reducer
//   )
//
//   return (
//     <Provider store={store}>
//       <SummaryPage/>
//     </Provider>
//   )
// }
//
// export default Summary


import Summary from "./Summary";
export default Summary;
