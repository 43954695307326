import React, {useEffect} from 'react';
import {Loading} from "@geist-ui/react";

const BadLogin = () => {

    useEffect(() => {
        window.location.replace('https://www.takkt.io/auth/')
    }, [])

    return (<Loading/>);
};

export default BadLogin
