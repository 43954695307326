import React, {useEffect, useState} from 'react';
import {Button, Popover, useTheme} from "@geist-ui/react";
import {Archive, MoreVertical} from "@geist-ui/react-icons";
import {useMutation} from "@apollo/client";
import {ARCHIVE_MEETING} from "../statements.graphql";

export type MeetingItemMenuProps = {
    meetingId: string
    showing?: boolean
    refetch: () => void
}

const MeetingItemMenu = (props: MeetingItemMenuProps) => {

    const [visible, setVisible] = useState(false)
    const theme = useTheme()

    const [archiveMutation] = useMutation(ARCHIVE_MEETING)

    const archive = () => {
        archiveMutation({
            variables: {
                meetingId: props.meetingId
            }
        }).then(() => props.refetch())
    }

    const PopoverContent = () => {
        return <>
            <Popover.Item>
                <Button type={"abort"} size={"small"} auto icon={<Archive/>} onClick={archive}>
                    Archive
                </Button>
            </Popover.Item>
        </>
    }

    const changeHandler = (next: boolean) => {
        setVisible(next)
    }

    useEffect(() => {
        if (visible && props.showing === false) {
            setVisible(false)
        }
    }, [props.showing])

    return (
        <div style={{paddingLeft: '20px'}}>
            <Popover visible={visible} onVisibleChange={changeHandler} content={PopoverContent} placement={"left"}>
                <MoreVertical color={theme.palette.secondary}/>
            </Popover>
        </div>
    );
};

export default MeetingItemMenu;
