import React, {useState} from 'react';
import {
    Container,
    Row,
    Page,
    Spacer,
    Text,
    Button,
    Modal,
    Tabs,
    Input,
    useInput,
    useModal, useTabs, Tooltip, Link as GeistLink, Avatar, Popover
} from '@geist-ui/react'
import {Link, useHistory, useLocation} from "react-router-dom";
import {QuestionCircle} from "@geist-ui/react-icons";
import {useMutation} from "@apollo/client";
import {USER_CREATE, USER_LOGIN} from "./statements.graphql";

import "./styles/global.css";
import UserMenu from "./components/UserMenu";

export type HeaderProps = {
    headerTitle: string
}


const Header = (props: HeaderProps) => {

    const {setVisible, bindings} = useModal()
    const {state: inputState, bindings: inputBindings} = useInput('')
    const {state: signup_mail, bindings: signupBindings} = useInput('')
    const {state: tab, bindings: tabBindings} = useTabs('1')
    const history = useHistory();

    const {state: nickname, bindings: nicknameBindings} = useInput('')

    const [loginMutation] = useMutation(USER_LOGIN)
    const [createMutation] = useMutation(USER_CREATE)

    const [, setMessage] = useState<string | undefined>(undefined)

    const {pathname} = useLocation()

    const submit = () => {
        setShowMessage(true)
        if (tab === '1') {
            loginMutation({variables: {email: inputState}})
              .then(() => setMessage('Check your email'))
        } else {
            createMutation({
                variables: {
                    email: signup_mail, nickname: nickname
                }
            }).then(() => setMessage(`Hey ${nickname}, check your email.`))
        }
    }

    const [showMessage, setShowMessage] = useState<boolean>(false);

    return (
        <Page.Header>

            <div style={{position: 'fixed', bottom: '15px', right: '15px', zIndex: 9999}}>
                <Tooltip text={<Text small style={{whiteSpace: 'nowrap'}}>Report a bug</Text>}
                         placement='left'>
                    <GeistLink color target="_blank"
                               href={'https://takkt.typeform.com/to/dHzU3895'}><QuestionCircle/></GeistLink>
                </Tooltip>
            </div>

            <Row justify={"space-between"} align={"middle"} style={{height: '60px'}}>
                <Container align={"middle"} style={{height: '100%'}}>
                    <Link to={'/'}>
                        {/*<img src={logo} alt={'logo'} height={37}/>*/}
                    </Link>
                    <Text small>Takkt</Text>
                    { props.headerTitle &&
                        <>
                            <Text>&nbsp;/&nbsp;</Text>
                            <Text small>{props.headerTitle}</Text>
                        </>
                    }

                </Container>

                <Container align="middle" style={{height: '100%'}}>
                    {localStorage.getItem('token') && <>

                        <Button auto ghost size={"mini"} type={pathname.includes('create') ? 'secondary' : 'default'}
                                onClick={() => history.push('/create')}>
                            New
                        </Button>
                        <Spacer x={0.5}/>
                        <Button auto ghost size={"mini"} type={pathname.includes('dashboard') ? 'secondary' : 'default'}
                                onClick={() => history.push('/dashboard')}>
                            Dashboard
                        </Button>
                        <Spacer x={0.5}/>
                        <Popover content={<UserMenu/>} style={{cursor: 'pointer'}}>
                            <Avatar text={'Me'}/>
                        </Popover>
                    </>

                    }
                    {!localStorage.getItem('token') &&
                    <>
                        <Spacer x={1}/>
                        <Button auto type={'success'} onClick={() => {
                            setVisible(true);
                            setShowMessage(false)
                        }}>Login or signup</Button>
                    </>
                    }
                </Container>
            </Row>

            <Modal {...bindings}>
                <Modal.Content>
                    { !showMessage &&
                    <>
                        <Tabs {...tabBindings}>
                            <Tabs.Item label="Login" value="1">
                                <Input width={'100%'} {...inputBindings} placeholder={'Email'}/>
                            </Tabs.Item>
                            <Tabs.Item label="Signup" value="2">
                                <Input width={'100%'} {...signupBindings} placeholder={'Email'}/>
                                <Spacer y={0.4}/>
                                <Input width={'100%'} {...nicknameBindings} placeholder={'Nickname'}/>
                            </Tabs.Item>
                        </Tabs>
                    </>
                    }
                    { showMessage &&
                    <>
                        <div style={{width: '100%', textAlign: 'center'}}>
                            <Text b style={{textAlign: 'center'}}>
                                Check your email &nbsp;✉️
                            </Text>
                        </div>
                    </>
                    }
                </Modal.Content>
                <Modal.Action passive onClick={() => {setVisible(false)}}>Cancel</Modal.Action>
                <Modal.Action type={'success'} onClick={submit}>Submit</Modal.Action>
            </Modal>
        </Page.Header>
    );
};

export default Header;
