import React from 'react';
import {Modal} from "@geist-ui/react";

const LeaveSeriesModal = ({modal, leaveSeries}: any) => {

    const {setVisible, visible, bindings} = modal

    return (
        <Modal {...bindings}>
            <Modal.Title>
                You are about to leave this series
            </Modal.Title>
            <Modal.Subtitle>
                Are you sure?
            </Modal.Subtitle>
            <Modal.Content>
                You wont be added to upcoming meetings but you will see meetings you attended.
            </Modal.Content>
            <Modal.Action passive onClick={() => setVisible(false)}>Cancel</Modal.Action>
            <Modal.Action onClick={() => leaveSeries()}>Leave</Modal.Action>
        </Modal>
    );
};

export default LeaveSeriesModal;
