import React, {useMemo, useState} from 'react';
import {Select} from "antd";
import {SeriesInterval} from "../../graphql/generated/graphql";
import moment from "moment";
import log from 'loglevel';

export type SelectSeriesIntervalProps = {
    meetingStartDate: moment.Moment
    setInterval: (value: SeriesInterval) => void
}

const SelectSeriesInterval = ({meetingStartDate, setInterval}: SelectSeriesIntervalProps) => {

    const [selectValue, setSelectValue] = useState<number | string | undefined>(undefined)

    const onSelect = (value: any, _option: any) => {
        setSelectValue(value)
        setInterval(value)
    }

    const weekdayStringMemo = useMemo(() => {
        const weekday = moment(meetingStartDate).isoWeekday()
        const weekdayStrings = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
        return weekdayStrings[weekday - 1] || ''
    }, [meetingStartDate])

    const intervalOptions: { label: string, value: SeriesInterval }[] = useMemo(() => {
        const normal = [{label: 'Every weekday', value: SeriesInterval.Daily},
            {label: `Weekly every ${weekdayStringMemo}`, value: SeriesInterval.Weekly}]

        const monthTh = (th: string) => [{
            label: `Monthly every ${th} ${weekdayStringMemo}`,
            value: SeriesInterval.Monthly
        }]

        const monthLast = [{label: `Monthly every last ${weekdayStringMemo}`, value: SeriesInterval.MonthlyLast}]

        if (meetingStartDate) {
            const monthWeek = meetingStartDate.week() - moment(meetingStartDate).startOf('month').week()

            if (monthWeek >= 3) {
                if (meetingStartDate.week() == moment(meetingStartDate).endOf("month").week()) {
                    log.trace('is last week')
                    return normal.concat(...monthLast)
                } else {
                    log.trace('is forth but not in last week of month')
                    if (moment(meetingStartDate).add(1, "week").month() == meetingStartDate.month()) {
                        log.trace('there exist another weekday')
                        return normal.concat(monthTh('fourth'))

                    } else {
                        log.trace('is last weekday this month')
                        return normal.concat(...monthTh('fourth')).concat(...monthLast)
                    }
                }
            }

            return normal.concat(monthTh(['first', 'second', 'third'][monthWeek]))
        } else return normal
    }, [meetingStartDate])

    return (
        <Select placeholder={"Select interval"} value={selectValue} onSelect={onSelect}
                options={intervalOptions}
        />
    );
};

export default SelectSeriesInterval;
